@import "~aftp-component-library/src/styles/theme";
:root {
  --logo-width-desktop: 35px;
  --logo-width-mobile: 25px;
  --logo-margin-top-desktop: 0px;
  --logo-margin-left-desktop: 0px;
  --logo-margin-top-mobile: 0px;
  --logo-margin-left-mobile: 0px;
  --header-title-font-size-desktop: 32px;
  --header-title-font-size-mobile: 22px;
  --home-page-icons-color: #{$white};
  --banner-title-color: #{$white};
  --logo-title-color: #{$white};
  --lp-card-bottom-gradient-color: #deaaff;
  // --primary-color: #{$purple--1};
  //--secondary-color:#{$blue--3};
}

//$primaryColor:var(--primary-color);
//$secondaryColor:var(--secondary-color);
$logo-width-desktop: var(--logo-width-desktop);
$logo-width-mobile: var(--logo-width-mobile);
$logo-margin-top-desktop: var(--logo-margin-top-desktop);
$logo-margin-left-desktop: var(--logo-margin-left-desktop);
$logo-margin-top-mobile: var(--logo-margin-top-mobile);
$logo-margin-left-mobile: var(--logo-margin-left-mobile);
$header-title-font-size-desktop: var(--header-title-font-size-desktop);
$header-title-font-size-mobile: var(--header-title-font-size-mobile);
$home-page-icons-color: var(--home-page-icons-color);
$banner-title-color: var(--banner-title-color);
$logo-title-color: var(--logo-title-color);
$lp-card-bottom-gradient-color: var(--lp-card-bottom-gradient-color);
