@import "~aftp-component-library/src/styles/theme";
@import "./themes.scss";
@import "./root.scss";
@import "./info-tooltip.scss";
@import "src/styles/_theme";
@import "src/styles/templates";
@import "src/styles/client-theme-configuration";

.custom-container {
  margin: 0 32px;
  padding-bottom: 32px;
  padding-top: 24px;
  @media screen and (max-width: 360px) {
    max-width: 82vw;
  }
  .row-no-gutters {
    margin-left: -12px;
    margin-right: -12px;
    .custom-gutter {
      padding: 0 12px;
    }
  }
}

@media only screen and (min-width: 1280px) and (max-width: 1919px) {
  .custom-container {
    max-width: 1280px;
    padding: 32px 68px;
    margin: 0 auto;
    padding-bottom: 68px;
    // margin-right: 79px;
    padding-top: 32px;

    &.auto-centered {
      margin-left: auto;
      margin-right: auto;
      max-width: px2rem(1280);
      padding: 32px 68px 68px;
    }
    .row-no-gutters {
      margin-left: -28px;
      margin-right: -28px;
      .custom-gutter {
        padding: 0 28px;
      }
    }
  }
}
.program-modal-backdrop,
.program-modal {
  z-index: 999 !important;
}
.assessment-question-title {
  font-size: px2em(24);
}
.lo-assessment-question-title {
  font-size: px2em(16);
}
.assessment-option {
  font-size: px2em(14);
}

@media only screen and (min-width: 1920px) {
  .custom-container {
    max-width: 1920px;
    padding: 0 76px;
    margin: 0 auto;
    &.auto-centered {
      margin-left: auto;
      margin-right: auto;
      max-width: px2rem(1280);
      padding: 32px 68px 68px;
    }
  }

  .assessment-question-title {
    font-size: px2em(16);
  }
  .assessment-option {
    font-size: px2em(22);
  }
}

$semi-black: #3f3356;
.color-semi-black {
  color: $semi-black;
}

.color-primary {
  color: $accent1;
}

.color-secondary {
  color: $blue--1;
}

.bordered-1 {
  border: solid 0.5px #6f668038;
}
.small-label {
  font: $font-size-12 "Graphik Regular", serif;
  color: #333;
  &.error {
    color: #ff647c;
  }
}
.control-label {
  font: $font-size-14 "Graphik Regular", serif;
  color: #333;
}
.label {
  font: $font-size-16 "Graphik Regular", serif;
  color: #333;
}
.header {
  margin-bottom: 2.1875rem;
  .home {
    vertical-align: baseline;
  }
}
.cursor-pointer {
  cursor: pointer;
}
.cursor-not-allowed {
  cursor: not-allowed !important;
}

.text-gray {
  color: #6f6680;
}
.text-dark-gray {
  color: #6f6680;
}
.text-darker-gray {
  color: #282e3a;
}
.custom-shadow {
  box-shadow: 0 7px 64px #00000012;
}
.bg-semi-gray {
  background: #f7f5f9;
}
.text-semi-gray {
  color: #6f6680;
}
.fill-semi-gray {
  fill: #6f6680;
}
.stroke-semi-gray {
  stroke: #6f6680;
}

.border-semi-gray {
  border-color: #f7f5f9 !important;
}
.semi-black {
  color: $semi-black;
}

.header-label {
  font: $font-size-24 "Graphik Medium", serif;
}

.custom-btn {
  text-transform: uppercase;
  font: $font-size-12 "Graphik Regular", serif;
  font-weight: bold;
  height: 32px;
  padding: 0 24px;
  letter-spacing: 0;
  outline: none !important;

  &.primary-btn {
    background: $primary-color 0 0 no-repeat padding-box;
    border: none;
    border-radius: 6px;
    color: white;
    &:focus {
      border: 2px solid $black;
    }
  }

  &.secondary-btn {
    background: #fff 0 0 no-repeat padding-box;
    border: 1px solid $primary-color;
    border-radius: 6px;
    color: $primary-color;
    &:focus {
      border: 2px solid $black;
    }
  }
}

.input-text {
  padding: 15px 24px;
  &:focus {
    border: 2px solid $black;
    outline: none;
  }
  &::placeholder {
    font-size: 0.9rem;
  }
}

.arrow {
  border: solid #989494;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
}

.date-input {
  &:focus {
    outline: none;
  }
}
.pointer-events-none {
  pointer-events: none;
}

.custom-scroll {
  scrollbar-width: thin;
  scrollbar-color: #f7f5f9;
  &::-webkit-scrollbar {
    width: px2rem(10);
  }

  &::-webkit-scrollbar-track {
    background-color: #f7f5f9;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: #d0c9d6;
    border-radius: 5px;
    &:hover {
      background: #b8b3bd;
    }
  }
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-x-auto {
  overflow-x: auto;
}

.overflow-visible {
  overflow: visible;
}

.h-screen {
  height: 100vh;
}
.w-screen {
  width: 100vw;
}
.fs-12 {
  font-size: $font-size-12;
}
.fs-14 {
  font-size: $font-size-14;
}
.fs-16 {
  font-size: $font-size-16;
}
.fs-18 {
  font-size: $font-size-18;
}
.fs-20 {
  font-size: $font-size-20;
}
.fs-24 {
  font-size: $font-size-24;
}
.fs-32 {
  font-size: $font-size-32;
}
.graphik-thin {
  font-family: "Graphik Thin", sans-serif;
}
.graphik-thin-italic {
  font-family: "Graphik Thin Italic", sans-serif;
}
.graphik-extra-light {
  font-family: "Graphik Extralight", sans-serif;
}
.graphik-extra-light-italic {
  font-family: "Graphik Extralight Italic", sans-serif;
}
.graphik-light {
  font-family: "Graphik Light", sans-serif;
}
.graphik-light-italic {
  font-family: "Graphik Light Italic", sans-serif;
}
.graphik {
  font-family: "Graphik Regular", sans-serif;
}
.graphik-italic {
  font-family: "Graphik Regular Italic", sans-serif;
}
.graphik-medium {
  font-family: "Graphik Medium", sans-serif;
}
.graphik-medium-italic {
  font-family: "Graphik Medium Italic", sans-serif;
}
.graphik-semibold {
  font-family: "Graphik Semibold", sans-serif;
}
.graphik-semibold-italic {
  font-family: "Graphik Semibold Italic", sans-serif;
}
.graphik-bold {
  font-family: "Graphik Bold", sans-serif;
}
.graphik-bold-italic {
  font-family: "Graphik Bold Italic", sans-serif;
}
.graphik-black {
  font-family: "Graphik Black", sans-serif;
}
.graphik-black-italic {
  font-family: "Graphik Black Italic", sans-serif;
}

.custom-checkbox {
  display: block;
  position: relative;
  padding-left: 13px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    &:checked ~ .checkmark {
      background-color: #0073d5;
      border: 1px solid #0073d5;
      &::after {
        display: block;
      }
    }
  }

  .checkmark {
    border-radius: 4px;
    height: px2rem(16);
    width: px2rem(16);
    border: 1px solid #6f6680;
    &::after {
      content: "";
      position: absolute;
      display: none;
      left: 6px;
      top: 3.9px;
      width: px2rem(5);
      height: 8px;
      border: solid white;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }

    &.double {
      height: px2rem(20);
      width: px2rem(20);
      &::after {
        left: 7px;
        top: 4.1px;
        width: px2rem(6);
        height: 10px;
        border-width: 0 2.9px 2.9px 0;
      }
    }
  }
}

.aftp-reminder-modal-panel {
  width: px2rem(544);

  @include xl-screen() {
    width: px2rem(856);
  }
}

//for disabling edit-skill-level modal pop-up scroll in configure-competency
.mat-dialog-container.ng-trigger {
  overflow: inherit;
  ::ng-deep {
    .aftp-button {
      &._secondary {
        color: green;
      }
    }
  }
}

//Disable scrolling in pop-up appearing in html
.disable-default-scroll {
  overflow-y: hidden;
  position: relative;
}

//Text Colors
.color-blue--1 {
  color: $blue--1; //#0041f0
}
.color-blue--2 {
  color: $blue--2; //#364049
}
.color-blue--3 {
  color: $blue--3; //#0084f4
}
.color-green--1 {
  color: $green--1; //#00c48c
}
.color-green--2 {
  color: $green--2; //#d5f2ea
}
.color-orange--1 {
  color: $orange--1; //#ffa26b
}
.color-orange--2 {
  color: $orange--2; //#ffe8da
}
.color-red--1 {
  color: $red--1; //#ff647c
}
.color-red--2 {
  color: $red--2; //#fbe4e8
}
.color-yellow--1 {
  color: $yellow--1; //#ffcf5c
}
.color-yellow--2 {
  color: $yellow--2; //#fff8e7
}
.color-purple--1 {
  color: $primary-color; //#a100ff
}
.color-gray--1 {
  color: $gray--1; //#1a051d
}
.color-gray--2 {
  color: $gray--2; //#3f3356
}
.color-gray--3 {
  color: $gray--3; //#6f6680
}
.color-gray--4 {
  color: $gray--4; //#9e8aa
}
.color-gray--5 {
  color: $gray--5; //#d0c9d6
}
.color-gray--6 {
  color: $gray--6; //#ece9f1
}
.color-gray--7 {
  color: $gray--7; //#f7f5f9
}
.color-gray--8 {
  color: $gray--8; //#faf9fb
}
.color-gray--9 {
  color: $gray--9; //#00000012
}
.color-gray--10 {
  color: $gray--10; //#0000007f
}
.color-gray--11 {
  color: $gray--11; //#282e3a
}
.color-gradient-1-1 {
  color: $gradient-1-1-color; //#b455aa
}
.color-gradient-1-2 {
  color: $gradient-1-2-color; //#4c7af4
}
.color-gradient-2-1 {
  color: $gradient-2-1-color; //#a055f5
}
.color-gradient-2-2 {
  color: $gradient-2-2-color; //#668df6
}
.color-gradient-3-1 {
  color: $gradient-3-1-color; //#be82ff
}
.color-gradient-3-2 {
  color: $gradient-3-2-color; //#282e3a
}
.color-gradient-4-1 {
  color: $gradient-4-1-color; //#dcafff
}
.color-gradient-4-2 {
  color: $gradient-4-2-color; //#99b3f9
}
.color-gradient-5-1 {
  color: $gradient-5-1-color; //#e6dcff
}
.color-gradient-5-2 {
  color: $gradient-5-2-color; //#b2c6fa
}

.search-collapsed {
  width: px2rem(40);
  opacity: 0;
  transition: width $default-transition-duration,
    opacity $default-transition-duration;

  &.collapsed {
    width: px2rem(344);
    opacity: 1;

    & + .search-icon {
      color: $secondary-color;
    }
  }

  .mat-form-field {
    width: 100%;
  }

  ::ng-deep {
    .mat-focused {
      .mat-form-field-flex {
        outline: none;
      }
    }

    .mat-form-field-appearance-outline {
      .mat-form-field-wrapper {
        margin: 0;
        padding-bottom: 0;
      }

      .mat-form-field-infix {
        padding: 0;
        border-top: 0;
      }

      input.mat-input-element {
        $search-input-height: px2rem(32);
        height: $search-input-height;
        line-height: $search-input-height;
        font-size: px2rem(11);
        color: $main-body-text-color;
        caret-color: $main-body-text-color;
        &::placeholder {
          color: $gray--12;
          font: italic normal normal 14px/12px "Graphik", sans-serif !important;
          letter-spacing: 0px;
        }
      }

      .mat-form-field-outline,
      .mat-form-field-prefix,
      .mat-form-field-suffix {
        top: 0;
      }

      .mat-form-field-suffix {
        align-self: center;
      }

      .mat-form-field-flex {
        margin-top: 0;
        padding-right: px2rem(40);
        border-radius: $default-border-radius;
        background-color: $white;
      }

      .mat-form-field-outline-start {
        border-radius: $default-border-radius 0 0 $default-border-radius;
      }

      .mat-form-field-outline-end {
        border-radius: 0 $default-border-radius $default-border-radius 0;
      }

      &.mat-form-field-invalid.mat-form-field-invalid
        .mat-form-field-outline-thick,
      .mat-form-field-outline {
        color: $border-color;
      }
    }
  }
}
