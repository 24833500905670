// Actionable
$accent1: #a100ff; //$purple--1
$accent2: #0041f0; //$blue--1
$accent3: #0045ff;
// Non-Actionable
// background,Badges or Icons
// solid color
$violet: #1a051d; //$gray--1
$fuchsia: #b455aa; //$gradient-1-1-color
$azure-radiance: #007bff;
$medium-slate-blue: #a055f5; //$gradient-2-1-color
$heliotrope: #be82ff; //$gradient-3-1-color
$mauve: #dcafff; //gradient-4-1-color
$lavender: #e6dcff; //$gradient-5-1-color
$mauve-light: #e6baff;
$magnolia: #fbf5ff;
$cultured: #f7f5f9; //$gray--7
//
$azure: #0073d5; //$blue--3
$royal-blue: #4c7af4; //$gradient-1-2-color
$cornflower-blue: #668df6; //$gradient-2-2-color
$jordy-blue: #80a0f7; //$gradient-3-2-color
$home-bg: #446670;
$perano: #99b3f9; //$gradient-4-2-color
$tropical-blue: #b2c6fa; //$gradient-5-2-color
$periwinkle: #c1d2ff;
$solitude: #e5ecff;
$alice-blue: #f8fbff;
$light-green: #6b06;
$green-shade-1: #6b0;
$completed-icon-green: #528135; //$green--1
$pale-blue: #edf7ff;
// Grey Palette
$black: #000;
$pallete-grey: #4b4a4a;
$martinique: #3f3356; //$gray--2
$nero: #191919; //body primary
$lighter-grey: #696969;
$disabled-grey-color: #6f6680; //$gray--4
$night-rider: #333;
$matterhorn-b: #4b4b4b;
$matterhorn-c: #4c4c4c;
$header-grey: #282e3a; //$gray--11
$dim-grey: #666;
$grey: #7f7f7f;
$dark-grey: #a3a3a3;
$copyright-grey: #6f6680; //$gray--4
$suva-grey: #939393;
$dot-gray: #6f6680;
$subheading-grey: #969696;
$mountain-mist: #939099;
$pastel-gray: #d0c9d6; //$gray--5
$very-light-grey: #ccc; //Menu Disabled
$mercury: #e5e5e5; //border
$white-smoke: #f2f2f2; //secondary background
$white-smoke-6: #f6f6f6;
$whisper: #ece9f1; //$gray--6
$white: #fff; //primary background ,$white
$light-grey: #f4f6fa; // headings
$shadow-grey: #00000029; //box-shadow
$jaguar: #00000012; //$gray--9
$menu-grey: #bcbcbc;
$off-white: #f2f5f5;
$menu-font-color: #707070;
$navy: #00000067;
$eclipse: #383838;
$rum: #6f6680; //$gray--3
$arrow-grey: #6f6680; //$gray--4
$light-grey-1: #6f6680;
$light-blue: #95989a;
$gray--12: #e6e6e6;
// Alert/Error
$christi: #6b0; //success
$light-red: #faf0f0;
$radical-red: #ff3246; //Error
$tomato-red: #ff647c; //$red--1
$selective-yellow: #ffb70f; //Alert
$alice-blue2: #edf7ff;
$red: #ff0101;
$very-light-pink: #f9ecf5;
$error-red: #c10100;
$success-green: #528135;
$success-aliceblue: #f9fef5;
$cobalt: #0056b3;
$light-black: #3c4657;
// Base Colors
// $primary-color: #ec1d23;
// $secondary-color: #333739;
// Color shades
// Accent colors
//  SHADOWS
//  BORDERS
// Font family
//  FONT WEIGHT
$font-weight-extra-bold: 800;
$font-weight-bold: 700;
$font-weight-semi-bold: 600;
$font-weight-regular: 400;
$font-weight-light: 300;
//  FONT SIZE
$font-size-9: 0.563rem; //9px;
$font-size-10: 0.625rem; //10px;
$font-size-11: 0.6875rem; //11px
$font-size-12: 0.75rem; //12px
$font-size-13: 0.8125rem; //13px
$font-size-14: 0.875rem; //14px;
$font-size-16: 1rem; //16px;
$font-size-18: 1.125rem; //18px;
$font-size-20: 1.25rem; //20px; >
$font-size-21: 1.3125rem;
$font-size-22: 1.375rem; //22px;
$font-size-24: 1.5rem; //24px;>
$font-size-25: 1.5625rem; //25px;
$font-size-26: 1.625rem; //26px
$font-size-28: 1.75rem; //28px
$font-size-30: 1.875rem; //30px;
$font-size-32: 2rem; //32px;
$font-size-36: 2.25rem; //36px;
$font-size-42: 2.625rem; //42px;
$font-size-63: 3.937rem; //63px;
