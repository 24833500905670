@font-face {
  font-family: "Graphik Thin";
  src: url("../fonts/icomoon.eot");
  src: url("../fonts/Graphik-Thin-Web.eot");
  src: url("../fonts/Graphik-Thin-Web.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Graphik-Thin-Web.woff2") format("woff2"),
    url("../fonts/Graphik-Thin-Web.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik Thin Italic";
  src: url("../fonts/Graphik-ThinItalic-Web.eot");
  src: url("../fonts/Graphik-ThinItalic-Web.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/Graphik-ThinItalic-Web.woff2") format("woff2"),
    url("../fonts/Graphik-ThinItalic-Web.woff") format("woff");
  font-weight: 100;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik Extralight";
  src: url("../fonts/Graphik-Extralight-Web.eot");
  src: url("../fonts/Graphik-Extralight-Web.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/Graphik-Extralight-Web.woff2") format("woff2"),
    url("../fonts/Graphik-Extralight-Web.woff") format("woff");
  font-weight: 200;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik Extralight Italic";
  src: url("../fonts/Graphik-ExtralightItalic-Web.eot");
  src: url("../fonts/Graphik-ExtralightItalic-Web.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/Graphik-ExtralightItalic-Web.woff2") format("woff2"),
    url("../fonts/Graphik-ExtralightItalic-Web.woff") format("woff");
  font-weight: 200;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik Light";
  src: url("../fonts/Graphik-Light-Web.eot");
  src: url("../fonts/Graphik-Light-Web.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Graphik-Light-Web.woff2") format("woff2"),
    url("../fonts/Graphik-Light-Web.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik Light Italic";
  src: url("../fonts/Graphik-LightItalic-Web.eot");
  src: url("../fonts/Graphik-LightItalic-Web.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/Graphik-LightItalic-Web.woff2") format("woff2"),
    url("../fonts/Graphik-LightItalic-Web.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik Regular";
  src: url("../fonts/Graphik-Regular-Web.eot");
  src: url("../fonts/Graphik-Regular-Web.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/Graphik-Regular-Web.woff2") format("woff2"),
    url("../fonts/Graphik-Regular-Web.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik Regular Italic";
  src: url("../fonts/Graphik-RegularItalic-Web.eot");
  src: url("../fonts/Graphik-RegularItalic-Web.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/Graphik-RegularItalic-Web.woff2") format("woff2"),
    url("../fonts/Graphik-RegularItalic-Web.woff") format("woff");
  font-weight: 400;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik Medium";
  src: url("../fonts/Graphik-Medium-Web.eot");
  src: url("../fonts/Graphik-Medium-Web.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Graphik-Medium-Web.woff2") format("woff2"),
    url("../fonts/Graphik-Medium-Web.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik Medium Italic";
  src: url("../fonts/Graphik-MediumItalic-Web.eot");
  src: url("../fonts/Graphik-MediumItalic-Web.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/Graphik-MediumItalic-Web.woff2") format("woff2"),
    url("../fonts/Graphik-MediumItalic-Web.woff") format("woff");
  font-weight: 500;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik Semibold";
  src: url("../fonts/Graphik-Semibold-Web.eot");
  src: url("../fonts/Graphik-Semibold-Web.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/Graphik-Semibold-Web.woff2") format("woff2"),
    url("../fonts/Graphik-Semibold-Web.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik Semibold Italic";
  src: url("../fonts/Graphik-SemiboldItalic-Web.eot");
  src: url("../fonts/Graphik-SemiboldItalic-Web.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/Graphik-SemiboldItalic-Web.woff2") format("woff2"),
    url("../fonts/Graphik-SemiboldItalic-Web.woff") format("woff");
  font-weight: 600;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik Bold";
  src: url("../fonts/Graphik-Bold-Web.eot");
  src: url("../fonts/Graphik-Bold-Web.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Graphik-Bold-Web.woff2") format("woff2"),
    url("../fonts/Graphik-Bold-Web.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik Bold Italic";
  src: url("../fonts/Graphik-BoldItalic-Web.eot");
  src: url("../fonts/Graphik-BoldItalic-Web.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/Graphik-BoldItalic-Web.woff2") format("woff2"),
    url("../fonts/Graphik-BoldItalic-Web.woff") format("woff");
  font-weight: 700;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik Black";
  src: url("../fonts/Graphik-Black-Web.eot");
  src: url("../fonts/Graphik-Black-Web.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Graphik-Black-Web.woff2") format("woff2"),
    url("../fonts/Graphik-Black-Web.woff") format("woff");
  font-weight: 800;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik Black Italic";
  src: url("../fonts/Graphik-BlackItalic-Web.eot");
  src: url("../fonts/Graphik-BlackItalic-Web.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/Graphik-BlackItalic-Web.woff2") format("woff2"),
    url("../fonts/Graphik-BlackItalic-Web.woff") format("woff");
  font-weight: 800;
  font-style: italic;
  font-stretch: normal;
}
