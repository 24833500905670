@font-face {
  font-family: "icomoon";
  src: url("../fonts/icomoon.eot?h0zk2508");
  src: url("../fonts/icomoon.eot?h0zk2508#iefix") format("embedded-opentype"),
    url("../fonts/icomoon.ttf?h0zk2508") format("truetype"),
    url("../fonts/icomoon.woff?h0zk2508") format("woff"),
    url("../fonts/icomoon.svg?h0zk2508#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  /* stylelint-disable font-family-no-missing-generic-family-keyword */
  font-family: "icomoon", serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-feedback-smile::before {
  content: "\e984";
  color: #0e75ff;
}
.icon-2::before {
  content: "\e903";
  color: #b9b9b9;
}
.icon-4::before {
  content: "\e904";
  color: #b9b9b9;
}
.icon-1::before {
  content: "\e905";
  color: #b9b9b9;
}
.icon-6::before {
  content: "\e907";
  color: #b9b9b9;
}
.icon-3::before {
  content: "\e908";
  color: #b9b9b9;
}
.icon-5::before {
  content: "\e921";
  color: #b9b9b9;
}
.icon-Icons::before {
  content: "\e90b";
}
.icon-Group-2 .path1::before {
  content: "\e92a";
}
.icon-Group-2 .path2::before {
  content: "\e930";
  margin-left: -0.9716796875rem;
}
.icon-Browse-1::before {
  content: "\e92b";
  color: #a7a7a7;
}
.icon-Menu-1::before {
  content: "\e92c";
}
.icon-rubbish-bin::before {
  content: "\e90c";
}
.icon-pencil-edit-button-3::before {
  content: "\e90d";
}
.icon-comment::before {
  content: "\e90e";
}
.icon-like::before {
  content: "\e90f";
}
.icon-menu-4::before {
  content: "\e910";
}
.icon-menu-button-1::before {
  content: "\e911";
}
.icon-error::before {
  content: "\e912";
}
.icon-cross::before {
  content: "\e92d";
}
.icon-warning-3::before {
  content: "\e90a";
}
.icon-stopwatch::before {
  content: "\e913";
}
.icon-0::before {
  content: "\e906";
}
.icon-Option-1::before {
  content: "\e92f";
}
.icon-iconfinder_timer_172599-3::before {
  content: "\e923";
}
.icon-iconfinder_misc-_alert_1276878-1::before {
  content: "\e924";
}
.icon-Business-Unit::before {
  content: "\e909";
}
.icon-Carousel-Arrow-Back::before {
  content: "\e914";
  color: #707070;
}
.icon-Carousel-Arrow-Front--1::before {
  content: "\e915";
  color: #707070;
}
.icon-Department .path1::before {
  content: "\e916";
  color: rgb(112, 112, 112);
}
.icon-Department .path2::before {
  content: "\e917";
  margin-left: -1rem;
  color: rgb(255, 255, 255);
}
.icon-Department .path3::before {
  content: "\e918";
  margin-left: -1rem;
  color: rgb(112, 112, 112);
}
.icon-Department .path4::before {
  content: "\e919";
  margin-left: -1rem;
  color: rgb(255, 255, 255);
}
.icon-Designation::before {
  content: "\e91a";
  color: #707070;
}
.icon-Groups::before {
  content: "\e91b";
}
.icon-Icons-Profile-Clock .path1::before {
  content: "\e91c";
  color: rgb(112, 112, 112);
}
.icon-Icons-Profile-Clock .path2::before {
  content: "\e91d";
  margin-left: -1rem;
  color: rgb(255, 255, 255);
}
.icon-Icons-Profile-Star .path1::before {
  content: "\e91e";
  color: rgb(112, 112, 112);
}
.icon-Icons-Profile-Star .path2::before {
  content: "\e91f";
  margin-left: -1rem;
  color: rgb(255, 255, 255);
}
.icon-Region::before {
  content: "\e920";
  color: #707070;
}
.icon-like-filled::before {
  content: "\e902";
}
.icon-question-1::before {
  content: "\e900";
}
.icon-sort-down::before {
  content: "\e901";
}
.icon-platform-page::before {
  content: "\e925";
}
.icon-graduation-hat::before {
  content: "\e922";
}
.icon-star::before {
  content: "\e935";
}
.icon-logout::before {
  content: "\e92e";
}
.icon-user-key::before {
  content: "\e934";
}
.icon-filter .path1::before {
  content: "\e931";
  color: rgb(254, 254, 254);
}
.icon-filter .path2::before {
  content: "\e936";
  margin-left: -1rem;
  color: #7811c9;
}
.icon-forward-arrow .path1::before {
  content: "\e932";
  color: rgb(9, 31, 64);
}
.icon-forward-arrow .path2::before {
  content: "\e933";
  margin-left: -1rem;
  color: rgb(254, 254, 254);
}
.icon-profile-pic::before {
  content: "\e926";
  color: #a7a7a7;
}
.icon-summary::before {
  content: "\e927";
}
.icon-Back::before {
  content: "\e928";
}
.icon-danger::before {
  content: "\e929";
  color: #fc2b2b;
}
.icon-badge-coloured::before {
  content: "\e937";
  color: #ffd700;
}
.icon-badge::before {
  content: "\e938";
}
.icon-points::before {
  content: "\e93a";
}
.icon-time .path1::before {
  content: "\e93b";
  color: rgb(203, 203, 203);
}
.icon-time .path2::before {
  content: "\e93c";
  margin-left: -1rem;
  color: rgb(252, 253, 252);
}
.icon-time .path3::before {
  content: "\e93d";
  margin-left: -1rem;
  color: rgb(252, 253, 252);
}
.icon-pop-up::before {
  content: "\e93e";
  color: #fff;
}
.icon-points-coloured::before {
  content: "\e93f";
  color: #ffd700;
}
.icon-time-1 .path1::before {
  content: "\e940";
  color: rgb(255, 215, 0);
}
.icon-time-1 .path2::before {
  content: "\e941";
  margin-left: -1rem;
  color: rgb(252, 253, 252);
}
.icon-time-1 .path3::before {
  content: "\e942";
  margin-left: -1rem;
  color: rgb(252, 253, 252);
}
.icon-cross-mobile::before {
  content: "\e943";
  color: #c7c7c7;
}
.icon-cross-web::before {
  content: "\e944";
  color: #c0bfbf;
}
.icon-lb-image-1 .path1::before {
  content: "\e945";
  color: rgb(255, 120, 22);
}
.icon-lb-image-1 .path2::before {
  content: "\e946";
  margin-left: -1rem;
  color: rgb(255, 159, 0);
}
.icon-lb-image-1 .path3::before {
  content: "\e947";
  margin-left: -1rem;
  color: rgb(255, 212, 0);
}
.icon-lb-image-1 .path4::before {
  content: "\e948";
  margin-left: -1rem;
  color: rgb(255, 159, 0);
}
.icon-lb-image-1 .path5::before {
  content: "\e949";
  margin-left: -1rem;
  color: rgb(255, 159, 0);
}
.icon-lb-image-1 .path6::before {
  content: "\e94a";
  margin-left: -1rem;
  color: rgb(105, 124, 134);
}
.icon-lb-image-1 .path7::before {
  content: "\e94b";
  margin-left: -1rem;
  color: rgb(255, 120, 22);
}
.icon-lb-image-1 .path8::before {
  content: "\e94c";
  margin-left: -1rem;
  color: rgb(70, 90, 97);
}
.icon-lb-image-1 .path9::before {
  content: "\e94d";
  margin-left: -1rem;
  color: rgb(105, 124, 134);
}
.icon-lb-image-1 .path10::before {
  content: "\e94e";
  margin-left: -1rem;
  color: rgb(70, 90, 97);
}
.icon-lb-image .path1::before {
  content: "\e94f";
  color: rgb(255, 120, 22);
}
.icon-lb-image .path2::before {
  content: "\e950";
  margin-left: -1rem;
  color: rgb(255, 159, 0);
}
.icon-lb-image .path3::before {
  content: "\e951";
  margin-left: -1rem;
  color: rgb(255, 212, 0);
}
.icon-lb-image .path4::before {
  content: "\e952";
  margin-left: -1rem;
  color: rgb(255, 159, 0);
}
.icon-lb-image .path5::before {
  content: "\e953";
  margin-left: -1rem;
  color: rgb(255, 159, 0);
}
.icon-lb-image .path6::before {
  content: "\e954";
  margin-left: -1rem;
  color: rgb(105, 124, 134);
}
.icon-lb-image .path7::before {
  content: "\e955";
  margin-left: -1rem;
  color: rgb(255, 120, 22);
}
.icon-lb-image .path8::before {
  content: "\e956";
  margin-left: -1rem;
  color: rgb(70, 90, 97);
}
.icon-lb-image .path9::before {
  content: "\e957";
  margin-left: -1rem;
  color: rgb(105, 124, 134);
}
.icon-lb-image .path10::before {
  content: "\e958";
  margin-left: -1rem;
  color: rgb(70, 90, 97);
}
.icon-Back-Arrow::before {
  content: "\e939";
}
.icon-Edit-icon-1::before {
  content: "\e959";
  color: #6b6b6b;
}
.icon-Edit-icon .path1::before {
  content: "\e95a";
  color: rgb(14, 117, 255);
}
.icon-Edit-icon .path2::before {
  content: "\e95b";
  margin-left: -1rem;
  color: rgb(14, 117, 255);
}
.icon-Edit-icon .path3::before {
  content: "\e95c";
  margin-left: -1rem;
  color: rgb(255, 255, 255);
}
.icon-Edit-icon .path4::before {
  content: "\e95d";
  margin-left: -1rem;
  color: rgb(255, 255, 255);
}
.icon-Tick::before {
  content: "\e9a2";
}
.icon-Persona::before {
  content: "\e9a1";
}
.icon-graph-analysis::before {
  content: "\e960";
}
.icon-Learning-Board-Icon-Mobile-1::before {
  content: "\e961";
  color: #95989a;
}
.icon-Learning-Board-Icon-Web::before {
  content: "\e962";
  color: #95989a;
}
.icon-Learning-Path-Icon-Web .path1::before {
  content: "\e963";
  color: rgb(149, 152, 154);
  opacity: 0.7;
}
.icon-Learning-Path-Icon-Web .path2::before {
  content: "\e964";
  margin-left: -1.06640625rem;
  color: rgb(149, 152, 154);
  opacity: 0.3;
}
.icon-Learning-Path-Icon-Web .path3::before {
  content: "\e965";
  margin-left: -1.06640625rem;
  color: rgb(149, 152, 154);
  opacity: 0.3;
}
.icon-Learning-Path-Icon-Web .path4::before {
  content: "\e966";
  margin-left: -1.06640625rem;
  color: rgb(149, 152, 154);
}
.icon-Learning-Path-Icon-Web .path5::before {
  content: "\e967";
  margin-left: -1.06640625rem;
  color: rgb(149, 152, 154);
}
.icon-Learning-Path-Icon-Web .path6::before {
  content: "\e968";
  margin-left: -1.06640625rem;
  color: rgb(149, 152, 154);
}
.icon-Learning-Path-Icon-Web .path7::before {
  content: "\e969";
  margin-left: -1.06640625rem;
  color: rgb(149, 152, 154);
}
.icon-Learning-Path-Icon-Web .path8::before {
  content: "\e96a";
  margin-left: -1.06640625rem;
  color: rgb(149, 152, 154);
}
.icon-Learning-Path-Icon-Mobile .path1::before {
  content: "\e96b";
  color: rgb(149, 152, 154);
  opacity: 0.8;
}
.icon-Learning-Path-Icon-Mobile .path2::before {
  content: "\e96c";
  margin-left: -1.0712890625rem;
  color: rgb(149, 152, 154);
  opacity: 0.3;
}
.icon-Learning-Path-Icon-Mobile .path3::before {
  content: "\e96d";
  margin-left: -1.0712890625rem;
  color: rgb(149, 152, 154);
  opacity: 0.3;
}
.icon-Learning-Path-Icon-Mobile .path4::before {
  content: "\e96e";
  margin-left: -1.0712890625rem;
  color: rgb(149, 152, 154);
}
.icon-Learning-Path-Icon-Mobile .path5::before {
  content: "\e96f";
  margin-left: -1.0712890625rem;
  color: rgb(149, 152, 154);
}
.icon-Learning-Path-Icon-Mobile .path6::before {
  content: "\e970";
  margin-left: -1.0712890625rem;
  color: rgb(149, 152, 154);
}
.icon-Learning-Path-Icon-Mobile .path7::before {
  content: "\e971";
  margin-left: -1.0712890625rem;
  color: rgb(149, 152, 154);
}
.icon-Learning-Path-Icon-Mobile .path8::before {
  content: "\e972";
  margin-left: -1.0712890625rem;
  color: rgb(149, 152, 154);
}
.icon-Info-Icon-Web::before {
  content: "\e973";
  color: #d1d1d1;
}
.icon-Info-Icon-Mobile::before {
  content: "\e974";
  color: #d1d1d1;
}
.icon-Play-Icon-Resume-Learning .path1::before {
  content: "\e975";
  color: rgb(75, 75, 66);
  opacity: 0.7;
}
.icon-Play-Icon-Resume-Learning .path2::before {
  content: "\e976";
  margin-left: -1rem;
  color: rgb(255, 255, 255);
  opacity: 0.8;
}
.icon-Tour-Web-image .path1::before {
  content: "\e977";
  color: rgb(247, 247, 247);
}
.icon-Tour-Web-image .path2::before {
  content: "\e978";
  margin-left: -1.134765625rem;
  color: rgb(247, 247, 247);
}
.icon-Tour-Web-image .path3::before {
  content: "\e979";
  margin-left: -1.134765625rem;
  color: rgb(120, 16, 201);
  opacity: 0.5;
}
.icon-Tour-Web-image .path4::before {
  content: "\e97a";
  margin-left: -1.134765625rem;
  color: rgb(120, 16, 201);
  opacity: 0.5;
}
.icon-Tour-Web-image .path5::before {
  content: "\e97b";
  margin-left: -1.134765625rem;
  color: rgb(120, 16, 201);
}
.icon-star-filled::before {
  content: "\e97c";
}
.icon-Star-Rewards-icon .path1::before {
  content: "\e97d";
  color: rgb(255, 212, 0);
}
.icon-Star-Rewards-icon .path2::before {
  content: "\e97e";
  margin-left: -1rem;
  color: rgb(41, 131, 180);
}
.icon-star-Empty::before {
  content: "\e97f";
}
.icon-smiley-bck-circle::before {
  content: "\e980";
  color: #fff;
}
.icon-Incognito::before {
  content: "\e981";
  color: #707070;
}
.icon-Feedback-smile-icon::before {
  content: "\e982";
  color: #0e75ff;
}
.icon-Category-icons::before {
  content: "\e983";
  color: #c7c7c7;
}
.icon-search::before {
  content: "\e95e";
}
.icon-search-3::before {
  content: "\e95f";
  color: #95989a;
}
.icon-search-2::before {
  content: "\e985";
}
.icon-search-0::before {
  content: "\e98d";
  color: #95989a;
}
.icon-search-1::before {
  content: "\e98e";
  color: #95989a;
}
.icon-search-5::before {
  content: "\e987";
}
.icon-search-4::before {
  content: "\e986";
}
.icon-search-6::before {
  content: "\e992";
  color: #babbbd;
}
.icon-Notifications::before {
  content: "\e993";
  color: #a7a7a7;
}
.icon-Ellipses::before {
  content: "\e994";
  color: #a2a2a2;
}
.icon-Important .path1::before {
  content: "\e995";
  color: rgb(255, 0, 0);
}
.icon-Important .path2::before {
  content: "\e996";
  margin-left: -1rem;
  color: rgb(255, 255, 255);
}
.icon-Important .path3::before {
  content: "\e997";
  margin-left: -1rem;
  color: rgb(255, 255, 255);
}
.icon-Bell::before {
  content: "\e998";
}
.icon-Clapping-Hands .path1::before {
  content: "\e999";
  color: rgb(224, 170, 148);
}
.icon-Clapping-Hands .path2::before {
  content: "\e99a";
  margin-left: -0.7861328125rem;
  color: rgb(247, 222, 206);
}
.icon-Clapping-Hands .path3::before {
  content: "\e99b";
  margin-left: -0.7861328125rem;
  color: rgb(255, 172, 51);
}
.icon-Clapping-Hands .path4::before {
  content: "\e99c";
  margin-left: -0.7861328125rem;
  color: rgb(255, 172, 51);
}
.icon-Clapping-Hands .path5::before {
  content: "\e99d";
  margin-left: -0.7861328125rem;
  color: rgb(255, 172, 51);
}
.icon-Clapping-Hands .path6::before {
  content: "\e99e";
  margin-left: -0.7861328125rem;
  color: rgb(255, 172, 51);
}
.icon-Clapping-Hands .path7::before {
  content: "\e99f";
  margin-left: -0.7861328125rem;
  color: rgb(255, 172, 51);
}
.icon-Clapping-Hands .path8::before {
  content: "\e9a0";
  margin-left: -0.7861328125rem;
  color: rgb(255, 172, 51);
}
.icon-v2_back::before {
  content: "\e988";
  color: #5a5a5a;
}
.icon-v2_chat .path1::before {
  content: "\e989";
  color: rgb(25, 144, 193);
}
.icon-v2_chat .path2::before {
  content: "\e98a";
  margin-left: -1rem;
  color: rgb(255, 255, 255);
}
.icon-v2_chat .path3::before {
  content: "\e98b";
  margin-left: -1rem;
  color: rgb(255, 255, 255);
}
.icon-v2_chat .path4::before {
  content: "\e98c";
  margin-left: -1rem;
  color: rgb(255, 255, 255);
}
.icon-v2_clock::before {
  content: "\e98f";
  color: #696969;
}
.icon-v2_delete::before {
  content: "\e990";
  color: #ada9a9;
}
.icon-v2_down-arrow::before {
  content: "\e991";
  color: #ada9a9;
}
.icon-v2_like::before {
  content: "\e9a3";
  color: #ada9a9;
}
.icon-v2_pencil-edit-button::before {
  content: "\e9a4";
  color: #ada9a9;
}
.icon-v2_reply::before {
  content: "\e9a5";
  color: #ada9a9;
}
.icon-v2_up-arrow::before {
  content: "\e9a6";
  color: #ada9a9;
}
.icon-icon-v2-home::before {
  content: "\e9a7";
  color: #707070;
}
.icon-icon-v2medal .path1::before {
  content: "\e9a8";
  color: rgb(105, 105, 105);
}
.icon-icon-v2medal .path2::before {
  content: "\e9a9";
  margin-left: -0.845703125rem;
  color: rgba(126, 126, 126, 0.97);
}
.icon-icon-v2medal .path3::before {
  content: "\e9aa";
  margin-left: -0.845703125rem;
  color: rgba(126, 126, 126, 0.97);
}
.icon-icon-v2medal .path4::before {
  content: "\e9ab";
  margin-left: -0.845703125rem;
  color: rgb(105, 105, 105);
}
.icon-icon-v2medal .path5::before {
  content: "\e9ac";
  margin-left: -0.845703125rem;
  color: rgba(163, 163, 163, 0.97);
}
.icon-icon-v2medal .path6::before {
  content: "\e9ad";
  margin-left: -0.845703125rem;
  color: rgb(139, 139, 139);
}
.icon-icon-v2medal .path7::before {
  content: "\e9ae";
  margin-left: -0.845703125rem;
  color: rgb(214, 214, 214);
}
.icon-icon-v2medal .path8::before {
  content: "\e9af";
  margin-left: -0.845703125rem;
  color: rgb(177, 177, 177);
  opacity: 0.2;
}
.icon-icon-v2medal .path9::before {
  content: "\e9b0";
  margin-left: -0.845703125rem;
  color: rgb(105, 105, 105);
}
.icon-v2-person::before {
  content: "\e9b1";
  color: #4dd0e1;
}
.icon-v2-completed-stamp .path1::before {
  content: "\e9b2";
  color: rgb(0, 152, 56);
}
.icon-v2-completed-stamp .path2::before {
  content: "\e9b3";
  margin-left: -1rem;
  color: rgb(255, 255, 255);
}
.icon-v2-completed-stamp .path3::before {
  content: "\e9b4";
  margin-left: -1rem;
  color: rgb(255, 255, 255);
}
.icon-v2-completed-stamp .path4::before {
  content: "\e9b5";
  margin-left: -1rem;
  color: rgb(0, 152, 56);
}
.icon-v2-completed-stamp .path5::before {
  content: "\e9b6";
  margin-left: -1rem;
  color: rgb(0, 152, 56);
}
.icon-v2-completed-stamp .path6::before {
  content: "\e9b7";
  margin-left: -1rem;
  color: rgb(255, 255, 255);
}
.icon-v2-completed-stamp .path7::before {
  content: "\e9b8";
  margin-left: -1rem;
  color: rgb(255, 255, 255);
}
.icon-v2-completed-stamp .path8::before {
  content: "\e9b9";
  margin-left: -1rem;
  color: rgb(255, 255, 255);
}
.icon-v2-completed-stamp .path9::before {
  content: "\e9ba";
  margin-left: -1rem;
  color: rgb(255, 255, 255);
}
.icon-v2-completed-stamp .path10::before {
  content: "\e9bb";
  margin-left: -1rem;
  color: rgb(255, 255, 255);
}
.icon-v2-completed-stamp .path11::before {
  content: "\e9bc";
  margin-left: -1rem;
  color: rgb(255, 255, 255);
}
.icon-v2-completed-stamp .path12::before {
  content: "\e9bd";
  margin-left: -1rem;
  color: rgb(255, 255, 255);
}
.icon-v2-carousal-left-arrow::before {
  content: "\e9be";
  color: #707070;
}
.icon-v2-carousal-right-arrow::before {
  content: "\e9bf";
  color: #707070;
}
.icon-v2-completed-thumbnail::before {
  content: "\e9c0";
  color: #009064;
}
.icon-v2-skills-locked::before {
  content: "\e9c1";
  color: #fff;
}
.icon-v2-lock-thumbnail::before {
  content: "\e9c2";
  color: #707070;
}
.icon-v2-empty-icon .path1::before {
  content: "\e9c3";
  color: rgb(255, 255, 255);
}
.icon-v2-empty-icon .path2::before {
  content: "\e9c4";
  margin-left: -0.9736328125rem;
  color: rgb(159, 159, 159);
  opacity: 0.128;
}
.icon-v2-empty-icon .path3::before {
  content: "\e9c5";
  margin-left: -0.9736328125rem;
  color: rgb(90, 90, 90);
}
.icon-v2-empty-icon .path4::before {
  content: "\e9c6";
  margin-left: -0.9736328125rem;
  color: rgb(90, 90, 90);
}
.icon-v2-empty-icon .path5::before {
  content: "\e9c7";
  margin-left: -0.9736328125rem;
  color: rgb(90, 90, 90);
}
.icon-v2-empty-icon .path6::before {
  content: "\e9c8";
  margin-left: -0.9736328125rem;
  color: rgb(90, 90, 90);
}
.icon-v2-default-profile-icon::before {
  content: "\e9c9";
  color: #d5d7ff;
}
.icon-v2-green-tick .path1::before {
  content: "\e9ca";
  color: rgb(0, 144, 100);
}
.icon-v2-green-tick .path2::before {
  content: "\e9cb";
  margin-left: -1rem;
  color: transparent;
}
.icon-v2-green-tick .path3::before {
  content: "\e9cc";
  margin-left: -1rem;
  color: rgb(255, 255, 255);
}
.icon-v2-mob-no-results .path1::before {
  content: "\e9cd";
  color: rgb(255, 255, 255);
}
.icon-v2-mob-no-results .path2::before {
  content: "\e9ce";
  margin-left: -0.9716796875rem;
  color: rgb(227, 227, 227);
}
.icon-v2-mob-no-results .path3::before {
  content: "\e9cf";
  margin-left: -0.9716796875rem;
  color: rgb(245, 245, 245);
}
.icon-v2-mob-no-results .path4::before {
  content: "\e9d0";
  margin-left: -0.9716796875rem;
  color: rgb(118, 118, 118);
}
.icon-v2-mob-no-results .path5::before {
  content: "\e9d1";
  margin-left: -0.9716796875rem;
  color: rgb(90, 90, 90);
}
.icon-v2-mob-no-results .path6::before {
  content: "\e9d2";
  margin-left: -0.9716796875rem;
  color: rgb(0, 0, 0);
}
.icon-v2-mob-no-results .path7::before {
  content: "\e9d3";
  margin-left: -0.9716796875rem;
  color: rgb(221, 221, 221);
}
.icon-v2-mob-no-results .path8::before {
  content: "\e9d4";
  margin-left: -0.9716796875rem;
  color: rgb(92, 92, 92);
}
.icon-v2-mob-no-results .path9::before {
  content: "\e9d5";
  margin-left: -0.9716796875rem;
  color: rgb(162, 162, 162);
}
.icon-v2-mob-no-results .path10::before {
  content: "\e9d6";
  margin-left: -0.9716796875rem;
  color: rgb(221, 221, 221);
}
.icon-v2-mob-no-results .path11::before {
  content: "\e9d7";
  margin-left: -0.9716796875rem;
  color: rgb(221, 221, 221);
}
.icon-v2-mob-no-results .path12::before {
  content: "\e9d8";
  margin-left: -0.9716796875rem;
  color: rgb(221, 221, 221);
}
.icon-v2-mob-no-results .path13::before {
  content: "\e9d9";
  margin-left: -0.9716796875rem;
  color: rgb(221, 221, 221);
}
.icon-v2-mob-no-results .path14::before {
  content: "\e9da";
  margin-left: -0.9716796875rem;
  color: rgb(221, 221, 221);
}
.icon-v2-mob-no-results .path15::before {
  content: "\e9db";
  margin-left: -0.9716796875rem;
  color: rgb(221, 221, 221);
}
.icon-v2-mob-no-results .path16::before {
  content: "\e9dc";
  margin-left: -0.9716796875rem;
  color: rgb(221, 221, 221);
}
.icon-v2-mob-no-results .path17::before {
  content: "\e9dd";
  margin-left: -0.9716796875rem;
  color: rgb(221, 221, 221);
}
.icon-v2-mob-no-results .path18::before {
  content: "\e9de";
  margin-left: -0.9716796875rem;
  color: rgb(234, 231, 231);
  opacity: 0.185;
}
.icon-v2-mob-no-results .path19::before {
  content: "\e9df";
  margin-left: -0.9716796875rem;
  color: rgb(234, 231, 231);
  opacity: 0.185;
}
.icon-v2-mob-no-results .path20::before {
  content: "\e9e0";
  margin-left: -0.9716796875rem;
  color: rgb(234, 231, 231);
  opacity: 0.185;
}
.icon-v2-mob-no-results .path21::before {
  content: "\e9e1";
  margin-left: -0.9716796875rem;
  color: rgb(103, 102, 103);
}
.icon-v2-mob-no-results .path22::before {
  content: "\e9e2";
  margin-left: -0.9716796875rem;
  color: rgb(103, 102, 103);
}
.icon-v2-mob-no-results .path23::before {
  content: "\e9e3";
  margin-left: -0.9716796875rem;
  color: rgb(129, 129, 129);
}
.icon-v2-mob-no-results .path24::before {
  content: "\e9e4";
  margin-left: -0.9716796875rem;
  color: rgb(172, 172, 172);
}
.icon-v2-mob-no-results .path25::before {
  content: "\e9e5";
  margin-left: -0.9716796875rem;
  color: rgb(128, 128, 128);
}
.icon-v2-mob-no-results .path26::before {
  content: "\e9e6";
  margin-left: -0.9716796875rem;
  color: rgb(103, 103, 103);
}
.icon-v2-mob-no-results .path27::before {
  content: "\e9e7";
  margin-left: -0.9716796875rem;
  color: rgb(162, 162, 162);
}
.icon-v2-mob-no-results .path28::before {
  content: "\e9e8";
  margin-left: -0.9716796875rem;
  color: rgb(221, 221, 221);
}
.icon-v2-mob-no-results .path29::before {
  content: "\e9e9";
  margin-left: -0.9716796875rem;
  color: rgb(221, 221, 221);
}
.icon-v2-popout::before {
  content: "\e9ea";
  color: #606060;
}
.icon-v2-web-no-results .path1::before {
  content: "\e9eb";
  color: rgb(255, 255, 255);
}
.icon-v2-web-no-results .path2::before {
  content: "\e9ec";
  margin-left: -0.970703125rem;
  color: rgb(227, 227, 227);
}
.icon-v2-web-no-results .path3::before {
  content: "\e9ed";
  margin-left: -0.970703125rem;
  color: rgb(245, 245, 245);
}
.icon-v2-web-no-results .path4::before {
  content: "\e9ee";
  margin-left: -0.970703125rem;
  color: rgb(118, 118, 118);
}
.icon-v2-web-no-results .path5::before {
  content: "\e9ef";
  margin-left: -0.970703125rem;
  color: rgb(90, 90, 90);
}
.icon-v2-web-no-results .path6::before {
  content: "\e9f0";
  margin-left: -0.970703125rem;
  color: rgb(0, 0, 0);
}
.icon-v2-web-no-results .path7::before {
  content: "\e9f1";
  margin-left: -0.970703125rem;
  color: rgb(221, 221, 221);
}
.icon-v2-web-no-results .path8::before {
  content: "\e9f2";
  margin-left: -0.970703125rem;
  color: rgb(92, 92, 92);
}
.icon-v2-web-no-results .path9::before {
  content: "\e9f3";
  margin-left: -0.970703125rem;
  color: rgb(162, 162, 162);
}
.icon-v2-web-no-results .path10::before {
  content: "\e9f4";
  margin-left: -0.970703125rem;
  color: rgb(221, 221, 221);
}
.icon-v2-web-no-results .path11::before {
  content: "\e9f5";
  margin-left: -0.970703125rem;
  color: rgb(221, 221, 221);
}
.icon-v2-web-no-results .path12::before {
  content: "\e9f6";
  margin-left: -0.970703125rem;
  color: rgb(221, 221, 221);
}
.icon-v2-web-no-results .path13::before {
  content: "\e9f7";
  margin-left: -0.970703125rem;
  color: rgb(221, 221, 221);
}
.icon-v2-web-no-results .path14::before {
  content: "\e9f8";
  margin-left: -0.970703125rem;
  color: rgb(221, 221, 221);
}
.icon-v2-web-no-results .path15::before {
  content: "\e9f9";
  margin-left: -0.970703125rem;
  color: rgb(221, 221, 221);
}
.icon-v2-web-no-results .path16::before {
  content: "\e9fa";
  margin-left: -0.970703125rem;
  color: rgb(221, 221, 221);
}
.icon-v2-web-no-results .path17::before {
  content: "\e9fb";
  margin-left: -0.970703125rem;
  color: rgb(221, 221, 221);
}
.icon-v2-web-no-results .path18::before {
  content: "\e9fc";
  margin-left: -0.970703125rem;
  color: rgb(234, 231, 231);
  opacity: 0.185;
}
.icon-v2-web-no-results .path19::before {
  content: "\e9fd";
  margin-left: -0.970703125rem;
  color: rgb(234, 231, 231);
  opacity: 0.185;
}
.icon-v2-web-no-results .path20::before {
  content: "\e9fe";
  margin-left: -0.970703125rem;
  color: rgb(234, 231, 231);
  opacity: 0.185;
}
.icon-v2-web-no-results .path21::before {
  content: "\e9ff";
  margin-left: -0.970703125rem;
  color: rgb(103, 102, 103);
}
.icon-v2-web-no-results .path22::before {
  content: "\ea00";
  margin-left: -0.970703125rem;
  color: rgb(103, 102, 103);
}
.icon-v2-web-no-results .path23::before {
  content: "\ea01";
  margin-left: -0.970703125rem;
  color: rgb(129, 129, 129);
}
.icon-v2-web-no-results .path24::before {
  content: "\ea02";
  margin-left: -0.970703125rem;
  color: rgb(172, 172, 172);
}
.icon-v2-web-no-results .path25::before {
  content: "\ea03";
  margin-left: -0.970703125rem;
  color: rgb(128, 128, 128);
}
.icon-v2-web-no-results .path26::before {
  content: "\ea04";
  margin-left: -0.970703125rem;
  color: rgb(103, 103, 103);
}
.icon-v2-web-no-results .path27::before {
  content: "\ea05";
  margin-left: -0.970703125rem;
  color: rgb(162, 162, 162);
}
.icon-v2-web-no-results .path28::before {
  content: "\ea06";
  margin-left: -0.970703125rem;
  color: rgb(221, 221, 221);
}
.icon-v2-web-no-results .path29::before {
  content: "\ea07";
  margin-left: -0.970703125rem;
  color: rgb(221, 221, 221);
}
.icon-v2-thumbnail-smartphone::before {
  content: "\ea08";
}
.icon-v2-thumbnail-creative::before {
  content: "\ea09";
}
.icon-v2-thumbnail-design-tools::before {
  content: "\ea0a";
}
.icon-v2-thumbnail-monitor::before {
  content: "\ea0b";
}
.icon-v2-thumbnail-mortarboard::before {
  content: "\ea0c";
}
.icon-v2-thumbnail-noun_exam_2387961::before {
  content: "\ea0d";
}
.icon-v2-thumbnail-sheet::before {
  content: "\ea0e";
}
.icon-v2-location::before {
  content: "\ea0f";
  color: #00a68f;
}
.icon-v2-retake-icon::before {
  content: "\ea10";
  color: #473793;
}

.icon-v2-no-result-new .path1::before {
  content: "\ea12";
  color: rgb(255, 255, 255);
}
.icon-v2-no-result-new .path2::before {
  content: "\ea13";
  margin-left: -0.970703125rem;
  color: rgb(227, 227, 227);
}
.icon-v2-no-result-new .path3::before {
  content: "\ea14";
  margin-left: -0.970703125rem;
  color: rgb(245, 245, 245);
}
.icon-v2-no-result-new .path4::before {
  content: "\ea15";
  margin-left: -0.970703125rem;
  color: rgb(118, 118, 118);
}
.icon-v2-no-result-new .path5::before {
  content: "\ea16";
  margin-left: -0.970703125rem;
  color: rgb(90, 90, 90);
}
.icon-v2-no-result-new .path6::before {
  content: "\ea17";
  margin-left: -0.970703125rem;
  color: rgb(0, 0, 0);
}
.icon-v2-no-result-new .path7::before {
  content: "\ea18";
  margin-left: -0.970703125rem;
  color: rgb(221, 221, 221);
}
.icon-v2-no-result-new .path8::before {
  content: "\ea19";
  margin-left: -0.970703125rem;
  color: rgb(92, 92, 92);
}
.icon-v2-no-result-new .path9::before {
  content: "\ea1a";
  margin-left: -0.970703125rem;
  color: rgb(162, 162, 162);
}
.icon-v2-no-result-new .path10::before {
  content: "\ea1b";
  margin-left: -0.970703125rem;
  color: rgb(221, 221, 221);
}
.icon-v2-no-result-new .path11::before {
  content: "\ea1c";
  margin-left: -0.970703125rem;
  color: rgb(221, 221, 221);
}
.icon-v2-no-result-new .path12::before {
  content: "\ea1d";
  margin-left: -0.970703125rem;
  color: rgb(221, 221, 221);
}
.icon-v2-no-result-new .path13::before {
  content: "\ea1e";
  margin-left: -0.970703125rem;
  color: rgb(221, 221, 221);
}
.icon-v2-no-result-new .path14::before {
  content: "\ea1f";
  margin-left: -0.970703125rem;
  color: rgb(221, 221, 221);
}
.icon-v2-no-result-new .path15::before {
  content: "\ea20";
  margin-left: -0.970703125rem;
  color: rgb(221, 221, 221);
}
.icon-v2-no-result-new .path16::before {
  content: "\ea21";
  margin-left: -0.970703125rem;
  color: rgb(221, 221, 221);
}
.icon-v2-no-result-new .path17::before {
  content: "\ea22";
  margin-left: -0.970703125rem;
  color: rgb(221, 221, 221);
}
.icon-v2-no-result-new .path18::before {
  content: "\ea23";
  margin-left: -0.970703125rem;
  color: rgb(234, 231, 231);
  opacity: 0.185;
}
.icon-v2-no-result-new .path19::before {
  content: "\ea24";
  margin-left: -0.970703125rem;
  color: rgb(234, 231, 231);
  opacity: 0.185;
}
.icon-v2-no-result-new .path20::before {
  content: "\ea25";
  margin-left: -0.970703125rem;
  color: rgb(234, 231, 231);
  opacity: 0.185;
}
.icon-v2-no-result-new .path21::before {
  content: "\ea26";
  margin-left: -0.970703125rem;
  color: rgb(103, 102, 103);
}
.icon-v2-no-result-new .path22::before {
  content: "\ea27";
  margin-left: -0.970703125rem;
  color: rgb(103, 102, 103);
}
.icon-v2-no-result-new .path23::before {
  content: "\ea28";
  margin-left: -0.970703125rem;
  color: rgb(129, 129, 129);
}
.icon-v2-no-result-new .path24::before {
  content: "\ea29";
  margin-left: -0.970703125rem;
  color: rgb(172, 172, 172);
}
.icon-v2-no-result-new .path25::before {
  content: "\ea2a";
  margin-left: -0.970703125rem;
  color: rgb(128, 128, 128);
}
.icon-v2-no-result-new .path26::before {
  content: "\ea2b";
  margin-left: -0.970703125rem;
  color: rgb(103, 103, 103);
}
.icon-v2-no-result-new .path27::before {
  content: "\ea2c";
  margin-left: -0.970703125rem;
  color: rgb(162, 162, 162);
}
.icon-v2-no-result-new .path28::before {
  content: "\ea2d";
  margin-left: -0.970703125rem;
  color: rgb(221, 221, 221);
}
.icon-v2-no-result-new .path29::before {
  content: "\ea2e";
  margin-left: -0.970703125rem;
  color: rgb(221, 221, 221);
}
.icon-v2-skill-inprogress::before {
  content: "\ea2f";
  color: #a100ff;
}
.icon-v2-file-xls::before {
  content: "\ea11";
}
.icon-v2-file-ppt::before {
  content: "\ea30";
}
.icon-v2-file-pdf::before {
  content: "\ea31";
}
.icon-v2-file-doc::before {
  content: "\ea32";
}
.icon-v2-expired::before {
  content: "\ea33";
  color: #fff;
}
.icon-v2-solid-cross::before {
  content: "\ea34";
  color: #969696;
}
.icon-v2-download::before {
  content: "\ea35";
  color: #a100ff;
}
.icon-v2-solid-down-arrow::before {
  content: "\ea36";
  color: #d50000;
}
.icon-v2-solid-up-arrow::before {
  content: "\ea37";
  color: #009064;
}
.icon-v2-Publish::before {
  content: "\ea38";
  color: #696969;
}
.icon-v2-Save::before {
  content: "\ea39";
  color: #696969;
}
.icon-v2-unpublish::before {
  content: "\ea3a";
  color: rgba(0, 0, 0, 0.54);
}
.icon-manage-plus::before {
  content: "\ea3b";
  color: #a100ff;
}
.icon-v2-accordian-minus::before {
  content: "\ea3c";
  color: #fff;
}
.icon-v2-accordian-plus::before {
  content: "\ea3d";
  color: #fff;
}
.icon-v2-big-plus::before {
  content: "\ea3e";
  color: #fff;
}
.icon-v2-screenshot::before {
  content: "\ea3f";
  color: #a100ff;
}
.icon-v2-solid-play::before {
  content: "\ea40";
  color: #a100ff;
}
.icon-v2-noun_hourglass::before {
  content: "\ea41";
  color: #ff9300;
}
.icon-v2-target::before {
  content: "\ea42";
}
.icon-info_outline::before {
  content: "\ea43";
  color: #6f6f6f;
}
.icon-calendar::before {
  content: "\ea44";
  color: #969696;
}
.icon-unSetRemainder .path1::before {
  content: "\ea45";
  color: rgb(161, 0, 255);
}
.icon-unSetRemainder .path2::before {
  content: "\ea46";
  margin-left: -1rem;
  color: rgb(255, 255, 255);
}
.icon-unSetRemainder .path3::before {
  content: "\ea47";
  margin-left: -1rem;
  color: rgb(255, 255, 255);
}
.icon-unSetRemainder .path4::before {
  content: "\ea48";
  margin-left: -1rem;
  color: rgb(255, 255, 255);
}
.icon-setRemainder .path1::before {
  content: "\ea49";
  color: rgb(255, 255, 255);
}
.icon-setRemainder .path2::before {
  content: "\ea4a";
  margin-left: -1rem;
  color: rgb(221, 221, 221);
}
.icon-setRemainder .path3::before {
  content: "\ea4b";
  margin-left: -1rem;
  color: rgb(161, 0, 255);
}
.icon-setRemainder .path4::before {
  content: "\ea4c";
  margin-left: -1rem;
  color: rgb(161, 0, 255);
}
.icon-setRemainder .path5::before {
  content: "\ea4d";
  margin-left: -1rem;
  color: rgb(161, 0, 255);
}
.icon-disableRemainder .path1::before {
  content: "\ea4e";
  color: rgb(112, 112, 112);
}
.icon-disableRemainder .path2::before {
  content: "\ea4f";
  margin-left: -1rem;
  color: rgb(255, 255, 255);
}
.icon-disableRemainder .path3::before {
  content: "\ea50";
  margin-left: -1rem;
  color: rgb(255, 255, 255);
}
.icon-disableRemainder .path4::before {
  content: "\ea51";
  margin-left: -1rem;
  color: rgb(255, 255, 255);
}
.icon-v2-solid-info::before {
  content: "\ea52";
  color: #a100ff;
}
.icon-v2-red-exclamatory .path1::before {
  content: "\ea53";
  color: rgb(255, 1, 1);
}
.icon-v2-red-exclamatory .path2::before {
  content: "\ea54";
  margin-left: -1rem;
  color: rgb(255, 255, 255);
}
.icon-v2-red-exclamatory .path3::before {
  content: "\ea55";
  margin-left: -1rem;
  color: rgb(255, 255, 255);
}
.icon-Copy-Icon::before {
  content: "\ea56";
  color: #a100ff;
}
.icon-Assessment-Icon .path1::before {
  content: "\ea57";
  color: rgb(0, 69, 255);
}
.icon-Assessment-Icon .path2::before {
  content: "\ea58";
  margin-left: -1rem;
  color: rgb(0, 69, 255);
}
.icon-Assessment-Icon .path3::before {
  content: "\ea59";
  margin-left: -1rem;
  color: rgb(161, 0, 255);
}
.icon-Completed-Icon::before {
  content: "\ea5a";
  color: #009064;
}
.icon-Ham-menu-on .path1::before {
  content: "\ea5b";
  color: rgb(224, 232, 255);
}
.icon-Ham-menu-on .path2::before {
  content: "\ea5c";
  margin-left: -1rem;
  color: rgb(161, 39, 255);
}
.icon-Ham-menu-on .path3::before {
  content: "\ea5d";
  margin-left: -1rem;
  color: rgb(161, 39, 255);
}
.icon-Ham-menu-on .path4::before {
  content: "\ea5e";
  margin-left: -1rem;
  color: rgb(0, 69, 255);
}
.icon-Ham-menu .path1::before {
  content: "\ea5f";
  color: rgb(224, 232, 255);
}
.icon-Ham-menu .path2::before {
  content: "\ea60";
  margin-left: -1rem;
  color: rgb(150, 150, 150);
}
.icon-Ham-menu .path3::before {
  content: "\ea61";
  margin-left: -1rem;
  color: rgb(150, 150, 150);
}
.icon-Ham-menu .path4::before {
  content: "\ea62";
  margin-left: -1rem;
  color: rgb(105, 105, 105);
}
.icon-Info-icon::before {
  content: "\ea63";
  color: #0045ff;
}
.icon-LO-Wave-604x58px::before {
  content: "\ea64";
}
.icon-Notes-off--1 .path1::before {
  content: "\ea65";
  color: rgb(0, 69, 255);
}
.icon-Notes-off--1 .path2::before {
  content: "\ea66";
  margin-left: -1.25rem;
  color: rgb(161, 39, 255);
}
.icon-Notes-off .path1::before {
  content: "\ea68";
  color: rgb(105, 105, 105);
}
.icon-Notes-off .path2::before {
  content: "\ea69";
  margin-left: -1.25rem;
  color: rgb(106, 107, 108);
}
.icon-Survey-Icon .path1::before {
  content: "\ea6b";
  color: rgb(0, 69, 255);
}
.icon-Survey-Icon .path2::before {
  content: "\ea6c";
  margin-left: -1.3125rem;
  color: rgb(255, 255, 255);
}
.icon-Survey-Icon .path3::before {
  content: "\ea6d";
  margin-left: -1.3125rem;
  color: rgb(161, 0, 255);
}
.icon-Survey-Icon .path4::before {
  content: "\ea6e";
  margin-left: -1.3125rem;
  color: rgb(161, 0, 255);
}
.icon-Survey-Icon .path5::before {
  content: "\ea6f";
  margin-left: -1.3125rem;
  color: rgb(161, 0, 255);
}
.icon-Tick1::before {
  content: "\ea70";
  color: #009064;
}
.icon-Transcript-On .path1::before {
  content: "\ea71";
  color: rgb(161, 39, 255);
}
.icon-Transcript-On .path2::before {
  content: "\ea72";
  margin-left: -1.2666015625rem;
  color: rgb(0, 69, 255);
}
.icon-Transcript-off::before {
  content: "\ea73";
  color: #696969;
}
.icon-Expand-Icon::before {
  content: "\ea67";
  color: #969696;
}
.icon-playlist_on .path1::before {
  content: "\ea6a";
  color: rgb(0, 69, 255);
}
.icon-playlist_on .path2::before {
  content: "\ea74";
  margin-left: -1.1875rem;
  color: rgb(0, 69, 255);
}
.icon-playlist_on .path3::before {
  content: "\ea75";
  margin-left: -1.1875rem;
  color: rgb(161, 39, 255);
}
.icon-playlist_off::before {
  content: "\ea76";
  color: #696969;
}
.icon-skip_next::before {
  content: "\ea77";
  color: #696969;
  font-size: 24px;
}
.icon-Right-Arrow .path1::before {
  content: "\ea78";
  color: rgb(255, 255, 255);
}
.icon-Right-Arrow .path2::before {
  content: "\ea79";
  margin-left: -1rem;
  color: rgb(0, 69, 255);
}
.icon-pencil-edit-button::before {
  content: "\ea7a";
  color: #969696;
}
.icon-pencil-edit-button-red::before {
  content: "\ea7b";
  color: #ff0101;
}
.icon-exploreBlack::before {
  content: "\ea7c";
}
.icon-exploreWhite::before {
  content: "\ea7d";
  color: #fff;
}
.icon-completed-icon::before {
  content: "\ea7e";
  color: #04bf94;
}
.icon-skip_next-1::before {
  content: "\ea7f";
  // color: #a11dff;
  color: #7811c9;
  font-size: 24px;
}
.icon-New-Menu-Icon-Active .path1::before {
  content: "\ea80";
  color: rgb(224, 232, 255);
  opacity: 0.996;
}
.icon-New-Menu-Icon-Active .path2::before {
  content: "\ea81";
  margin-left: -1rem;
  color: rgb(161, 39, 255);
}
.icon-New-Menu-Icon-Active .path3::before {
  content: "\ea82";
  margin-left: -1rem;
  color: rgb(0, 69, 255);
}
.icon-New-Menu-Icon-Active .path4::before {
  content: "\ea83";
  margin-left: -1rem;
  color: rgb(161, 39, 255);
}
.icon-New-Menu-Icon-Not-Active .path1::before {
  content: "\ea84";
  color: rgb(224, 232, 255);
}
.icon-New-Menu-Icon-Not-Active .path2::before {
  content: "\ea85";
  margin-left: -1rem;
  color: rgb(105, 105, 105);
}
.icon-New-Menu-Icon-Not-Active .path3::before {
  content: "\ea86";
  margin-left: -1rem;
  color: rgb(105, 105, 105);
}
.icon-New-Menu-Icon-Not-Active .path4::before {
  content: "\ea87";
  margin-left: -1rem;
  color: rgb(105, 105, 105);
}
.icon-Disabled .path1::before {
  content: "\ea88";
  color: rgb(221, 221, 221);
}
.icon-Disabled .path2::before {
  content: "\ea89";
  margin-left: -1rem;
  color: rgb(178, 178, 178);
}
.icon-Disabled .path3::before {
  content: "\ea8a";
  margin-left: -1rem;
  color: rgb(178, 178, 178);
}
.icon-Disabled .path4::before {
  content: "\ea8b";
  margin-left: -1rem;
  color: rgb(178, 178, 178);
}
.icon-Expand-Purple-Solid-Arrow::before {
  content: "\ea8c";
  color: #a100ff;
}
.icon-Expand-Purple-Line-Arrow::before {
  content: "\ea8d";
  color: #a100ff;
}
.icon-Collapse-Grey-Solid-Arrow::before {
  content: "\ea8e";
  color: rgba(0, 0, 0, 0.54);
}
.icon-Collapse-Grey-Line-Arrow::before {
  content: "\ea8f";
}
.icon-dots-active .path1::before {
  content: "\ea90";
  color: rgb(224, 232, 255);
}
.icon-dots-active .path2::before {
  content: "\ea91";
  margin-left: -1rem;
  color: rgb(161, 39, 255);
}
.icon-dots-active .path3::before {
  content: "\ea92";
  margin-left: -1rem;
  color: rgb(0, 69, 255);
}
.icon-dots-active .path4::before {
  content: "\ea93";
  margin-left: -1rem;
  color: rgb(161, 39, 255);
}
