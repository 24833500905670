@import "~aftp-component-library/src/styles/theme";

// Default browser context
$browser-context: 16;
@function px2em($pixels, $context: $browser-context) {
  @return #{$pixels/$context}em;
}

// HTML font size
$html-font-size: 16;
@function px2rem($pixels, $context: $html-font-size) {
  @return #{$pixels/$context}rem;
}

$page-title-icon-color: lighten(#000, 50%);
$avatar-icon-color: $white;
$avatar-background-color: $gray--1;
$avatar-icon-color: $white;
$avatar-edit-icon-color: $blue--3;
$avatar-edit-background-color: $white;

//Fonts
$base-regular-font: "Arial";
$font-family--graphik-regular: "Graphik Regular", sans-serif;
$font-family--graphik-medium: "Graphik Medium", sans-serif;
$font-family--graphik-semi-bold: "Graphik Semibold", sans-serif;
$font-family--graphik-bold: "Graphik Bold", sans-serif;

//Icons
$page-title-icon-color: lighten(#000, 50%);

//Gutters
$xl-screen-gutter: px2em(80);
$lg-screen-gutter-number: 76; // from 1920px
$lg-screen-gutter: px2em($lg-screen-gutter-number); // from 1920px
$lg-screen-gutter-new: px2em(64); // from 540px to 1919px multiple of eight
$md-screen-gutter: px2em(68); // from 1280px to 1919px
$sm-screen-gutter-number: 56; // from 760px to 1280px
$sm-screen-gutter: px2em(56); // from 760px to 1280px
$xs-screen-gutter: px2em(32); // from 0px to 759px
$icon-text-gutter: px2em(10);

//Heights
$page-title-height: px2em(60);

//Dimensions
$header-height-xs: px2rem(48);
$header-height-lg: px2rem(70);
$header-height-xl: px2rem(102);
$footer-height-xs: px2rem(160);
$footer-height-lg: px2rem(70);
$header-height: px2em(60);
$footer-height: px2em(70);

//Animations
$default-transition-duration: 0.3s;
$primary-transition: $default-transition-duration linear;
$drag-short-animation: transform 300ms ease;
$drag-long-animation: transform 500ms ease;

//borders
$default-border-radius: px2rem(6);

//focus outline
%focus-outline-1 {
  outline: $outline-1;
  outline-offset: 0;
}

%focus-outline-2 {
  outline: $outline-2;
}

%focus-outline-3 {
  outline: $outline-3;
}
