@font-face {
  font-family: "HeroIcons-Font";
  src: url("fonts/HeroIcons-Font.eot?9mlk4t");
  src: url("fonts/HeroIcons-Font.eot?9mlk4t#iefix") format("embedded-opentype"),
    url("fonts/HeroIcons-Font.ttf?9mlk4t") format("truetype"),
    url("fonts/HeroIcons-Font.woff?9mlk4t") format("woff"),
    url("fonts/HeroIcons-Font.svg?9mlk4t#HeroIcons-Font") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

i {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "HeroIcons-Font" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.hero-academic-cap-solid:before {
  content: "\e900";
}

.hero-adjustments-solid:before {
  content: "\e901";
}

.hero-annotation-solid:before {
  content: "\e902";
}

.hero-archive-solid:before {
  content: "\e903";
}

.hero-arrow-circle-down-solid:before {
  content: "\e904";
}

.hero-arrow-circle-left-solid:before {
  content: "\e905";
}

.hero-arrow-circle-right-solid:before {
  content: "\e906";
}

.hero-arrow-circle-up-solid:before {
  content: "\e907";
}

.hero-arrow-down-solid:before {
  content: "\e908";
}

.hero-arrow-left-solid:before {
  content: "\e909";
}

.hero-arrow-narrow-down-solid:before {
  content: "\e90a";
}

.hero-arrow-narrow-left-solid:before {
  content: "\e90b";
}

.hero-arrow-narrow-right-solid:before {
  content: "\e90c";
}

.hero-arrow-narrow-up-solid:before {
  content: "\e90d";
}

.hero-arrow-right-solid:before {
  content: "\e90e";
}

.hero-arrow-up-solid:before {
  content: "\e90f";
}

.hero-arrows-expand-solid:before {
  content: "\e910";
}

.hero-at-symbol-solid:before {
  content: "\e911";
}

.hero-backspace-solid:before {
  content: "\e912";
}

.hero-badge-check-solid:before {
  content: "\e913";
}

.hero-ban-solid:before {
  content: "\e914";
}

.hero-beaker-solid:before {
  content: "\e915";
}

.hero-bell-solid:before {
  content: "\e916";
}

.hero-book-open-solid:before {
  content: "\e917";
}

.hero-bookmark-alt-solid:before {
  content: "\e918";
}

.hero-bookmark-solid:before {
  content: "\e919";
}

.hero-briefcase-solid:before {
  content: "\e91a";
}

.hero-cake-solid:before {
  content: "\e91b";
}

.hero-calendar-solid:before {
  content: "\e91c";
}

.hero-camera-solid:before {
  content: "\e91d";
}

.hero-cash-solid:before {
  content: "\e91e";
}

.hero-chart-bar-solid:before {
  content: "\e91f";
}

.hero-chart-pie-solid:before {
  content: "\e920";
}

.hero-chart-square-bar-solid:before {
  content: "\e921";
}

.hero-chat-alt-2-solid:before {
  content: "\e922";
}

.hero-chat-alt-solid:before {
  content: "\e923";
}

.hero-chat-solid:before {
  content: "\e924";
}

.hero-check-circle-solid:before {
  content: "\e925";
}

.hero-check-solid:before {
  content: "\e926";
}

.hero-cheveron-down-solid:before {
  content: "\e927";
}

.hero-cheveron-left-solid:before {
  content: "\e928";
}

.hero-cheveron-right-solid:before {
  content: "\e929";
}

.hero-cheveron-up-solid:before {
  content: "\e92a";
}

.hero-chevron-double-down-solid:before {
  content: "\e92b";
}

.hero-chevron-double-left-solid:before {
  content: "\e92c";
}

.hero-chevron-double-right-solid:before {
  content: "\e92d";
}

.hero-chevron-double-up-solid:before {
  content: "\e92e";
}

.hero-chip-solid:before {
  content: "\e92f";
}

.hero-clipboard-check-solid:before {
  content: "\e930";
}

.hero-clipboard-copy-solid:before {
  content: "\e931";
}

.hero-clipboard-list-solid:before {
  content: "\e932";
}

.hero-clock-solid:before {
  content: "\e933";
}

.hero-cloud-download-solid:before {
  content: "\e934";
}

.hero-cloud-solid:before {
  content: "\e935";
}

.hero-cloud-upload-solid:before {
  content: "\e936";
}

.hero-code-solid:before {
  content: "\e937";
}

.hero-cog-solid:before {
  content: "\e938";
}

.hero-collection-solid:before {
  content: "\e939";
}

.hero-color-swatch-solid:before {
  content: "\e93a";
}

.hero-credit-card-solid:before {
  content: "\e93b";
}

.hero-cube-solid:before {
  content: "\e93c";
}

.hero-cube-transparent-solid:before {
  content: "\e93d";
}

.hero-currency-bangladeshi-solid:before {
  content: "\e93e";
}

.hero-currency-dollar-solid:before {
  content: "\e93f";
}

.hero-currency-euro-solid:before {
  content: "\e940";
}

.hero-currency-pound-solid:before {
  content: "\e941";
}

.hero-currency-rupee-solid:before {
  content: "\e942";
}

.hero-currency-yen-solid:before {
  content: "\e943";
}

.hero-cursor-click-solid:before {
  content: "\e944";
}

.hero-database-solid:before {
  content: "\e945";
}

.hero-desktop-computer-solid:before {
  content: "\e946";
}

.hero-device-mobile-solid:before {
  content: "\e947";
}

.hero-document-add-solid:before {
  content: "\e948";
}

.hero-document-download-solid:before {
  content: "\e949";
}

.hero-document-duplicate-solid:before {
  content: "\e94a";
}

.hero-document-remove-solid:before {
  content: "\e94b";
}

.hero-document-report-solid:before {
  content: "\e94c";
}

.hero-document-search-solid:before {
  content: "\e94d";
}

.hero-document-solid:before {
  content: "\e94e";
}

.hero-document-text-solid:before {
  content: "\e94f";
}

.hero-dots-circle-horizontal-solid:before {
  content: "\e950";
}

.hero-dots-horizontal-solid:before {
  content: "\e951";
}

.hero-dots-vertical-solid:before {
  content: "\e952";
}

.hero-download-1-solid:before {
  content: "\e953";
}

.hero-download-solid:before {
  content: "\e954";
}

.hero-duplicate-solid:before {
  content: "\e955";
}

.hero-emoji-happy-solid:before {
  content: "\e956";
}

.hero-emoji-sad-solid:before {
  content: "\e957";
}

.hero-exclamation-circle-solid:before {
  content: "\e958";
}

.hero-exclamation-solid:before {
  content: "\e959";
}

.hero-external-link-solid:before {
  content: "\e95a";
}

.hero-eye-off-solid:before {
  content: "\e95b";
}

.hero-eye-solid:before {
  content: "\e95c";
}

.hero-fast-forward-solid:before {
  content: "\e95d";
}

.hero-film-solid:before {
  content: "\e95e";
}

.hero-filter-solid:before {
  content: "\e95f";
}

.hero-finger-print-solid:before {
  content: "\e960";
}

.hero-fire-solid:before {
  content: "\e961";
}

.hero-flag-solid:before {
  content: "\e962";
}

.hero-folder-add-solid:before {
  content: "\e963";
}

.hero-folder-download-solid:before {
  content: "\e964";
}

.hero-folder-open-solid:before {
  content: "\e965";
}

.hero-folder-remove-solid:before {
  content: "\e966";
}

.hero-folder-solid:before {
  content: "\e967";
}

.hero-gift-solid:before {
  content: "\e968";
}

.hero-globe-alt-solid:before {
  content: "\e969";
}

.hero-globe-solid:before {
  content: "\e96a";
}

.hero-hand-solid:before {
  content: "\e96b";
}

.hero-hashtag-solid:before {
  content: "\e96c";
}

.hero-heart-solid:before {
  content: "\e96d";
}

.hero-home-solid:before {
  content: "\e96e";
}

.hero-icon-currency-dollar-solid:before {
  content: "\e96f";
}

.hero-identification-solid:before {
  content: "\e970";
}

.hero-inbox-in-solid:before {
  content: "\e971";
}

.hero-inbox-solid:before {
  content: "\e972";
}

.hero-information-circle-solid:before {
  content: "\e973";
}

.hero-key-solid:before {
  content: "\e974";
}

.hero-light-bulb-solid:before {
  content: "\e975";
}

.hero-lightning-bolt-solid:before {
  content: "\e976";
}

.hero-link-solid:before {
  content: "\e977";
}

.hero-location-marker-solid:before {
  content: "\e978";
}

.hero-lock-closed-solid:before {
  content: "\e979";
}

.hero-lock-open-solid:before {
  content: "\e97a";
}

.hero-logout-1-solid:before {
  content: "\e97b";
}

.hero-logout-solid:before {
  content: "\e97c";
}

.hero-mail-open-solid:before {
  content: "\e97d";
}

.hero-mail-1-solid:before {
  content: "\e97e";
}

.hero-mail-solid:before {
  content: "\e97f";
}

.hero-map-solid:before {
  content: "\e980";
}

.hero-md-library-solid:before {
  content: "\e981";
}

.hero-menu-alt-1-solid:before {
  content: "\e982";
}

.hero-menu-alt-2-solid:before {
  content: "\e983";
}

.hero-menu-alt-3-solid:before {
  content: "\e984";
}

.hero-menu-alt-4-solid:before {
  content: "\e985";
}

.hero-menu-solid:before {
  content: "\e986";
}

.hero-microphone-solid:before {
  content: "\e987";
}

.hero-minus-circle-solid:before {
  content: "\e988";
}

.hero-minus-1-solid:before {
  content: "\e989";
}

.hero-minus-solid:before {
  content: "\e98a";
}

.hero-moon-solid:before {
  content: "\e98b";
}

.hero-music-note-solid:before {
  content: "\e98c";
}

.hero-newspaper-solid:before {
  content: "\e98d";
}

.hero-office-building-solid:before {
  content: "\e98e";
}

.hero-paper-airplane-solid:before {
  content: "\e98f";
}

.hero-paper-clip-solid:before {
  content: "\e990";
}

.hero-pause-solid:before {
  content: "\e991";
}

.hero-pencil-alt-solid:before {
  content: "\e992";
}

.hero-pencil-solid:before {
  content: "\e993";
}

.hero-phone-incoming-solid:before {
  content: "\e994";
}

.hero-phone-missed-call-solid:before {
  content: "\e995";
}

.hero-phone-outgoing-solid:before {
  content: "\e996";
}

.hero-phone-solid:before {
  content: "\e997";
}

.hero-photograph-solid:before {
  content: "\e998";
}

.hero-play-solid:before {
  content: "\e999";
}

.hero-plus-circle-solid:before {
  content: "\e99a";
}

.hero-plus-1-solid:before {
  content: "\e99b";
}

.hero-plus-solid:before {
  content: "\e99c";
}

.hero-presentation-chart-bar-solid:before {
  content: "\e99d";
}

.hero-presentation-chart-line-solid:before {
  content: "\e99e";
}

.hero-printer-solid:before {
  content: "\e99f";
}

.hero-puzzle-solid:before {
  content: "\e9a0";
}

.hero-qrcode-solid:before {
  content: "\e9a1";
}

.hero-question-mark-circle-solid:before {
  content: "\e9a2";
}

.hero-receipt-refund-solid:before {
  content: "\e9a3";
}

.hero-receipt-tax-solid:before {
  content: "\e9a4";
}

.hero-refresh-solid:before {
  content: "\e9a5";
}

.hero-reply-solid:before {
  content: "\e9a6";
}

.hero-rewind-solid:before {
  content: "\e9a7";
}

.hero-rss-solid:before {
  content: "\e9a8";
}

.hero-save-as-solid:before {
  content: "\e9a9";
}

.hero-save-solid:before {
  content: "\e9aa";
}

.hero-scale-solid:before {
  content: "\e9ab";
}

.hero-scissors-solid:before {
  content: "\e9ac";
}

.hero-search-circle-solid:before {
  content: "\e9ad";
}

.hero-search-solid:before {
  content: "\e9ae";
}

.hero-selector-solid:before {
  content: "\e9af";
}

.hero-server-solid:before {
  content: "\e9b0";
}

.hero-share-solid:before {
  content: "\e9b1";
}

.hero-shield-check-solid:before {
  content: "\e9b2";
}

.hero-shield-exclamation-solid:before {
  content: "\e9b3";
}

.hero-shopping-bag-solid:before {
  content: "\e9b4";
}

.hero-sort-ascending-solid:before {
  content: "\e9b5";
}

.hero-sort-descending-solid:before {
  content: "\e9b6";
}

.hero-sparkles-solid:before {
  content: "\e9b7";
}

.hero-speakerphone-solid:before {
  content: "\e9b8";
}

.hero-star-solid:before {
  content: "\e9b9";
}

.hero-status-offline-solid:before {
  content: "\e9ba";
}

.hero-status-online-solid:before {
  content: "\e9bb";
}

.hero-stop-solid:before {
  content: "\e9bc";
}

.hero-sun-solid:before {
  content: "\e9bd";
}

.hero-support-solid:before {
  content: "\e9be";
}

.hero-switch-horizontal-solid:before {
  content: "\e9bf";
}

.hero-switch-vertical-solid:before {
  content: "\e9c0";
}

.hero-table-1-solid:before {
  content: "\e9c1";
}

.hero-table-solid:before {
  content: "\e9c2";
}

.hero-tag-solid:before {
  content: "\e9c3";
}

.hero-template-solid:before {
  content: "\e9c4";
}

.hero-terminal-solid:before {
  content: "\e9c5";
}

.hero-thumb-down-solid:before {
  content: "\e9c6";
}

.hero-thumb-up-solid:before {
  content: "\e9c7";
}

.hero-ticket-solid:before {
  content: "\e9c8";
}

.hero-translate-solid:before {
  content: "\e9c9";
}

.hero-trash-solid:before {
  content: "\e9ca";
}

.hero-trending-down-solid:before {
  content: "\e9cb";
}

.hero-trending-up-solid:before {
  content: "\e9cc";
}

.hero-truck-solid:before {
  content: "\e9cd";
}

.hero-upload-solid:before {
  content: "\e9ce";
}

.hero-user-add-solid:before {
  content: "\e9cf";
}

.hero-user-circle-solid:before {
  content: "\e9d0";
}

.hero-user-group-solid:before {
  content: "\e9d1";
}

.hero-user-remove-solid:before {
  content: "\e9d2";
}

.hero-user-solid:before {
  content: "\e9d3";
}

.hero-users-solid:before {
  content: "\e9d4";
}

.hero-variable-solid:before {
  content: "\e9d5";
}

.hero-video-camera-solid:before {
  content: "\e9d6";
}

.hero-view-boards-solid:before {
  content: "\e9d7";
}

.hero-view-grid-add-solid:before {
  content: "\e9d8";
}

.hero-view-grid-solid:before {
  content: "\e9d9";
}

.hero-view-list-solid:before {
  content: "\e9da";
}

.hero-volume-off-solid:before {
  content: "\e9db";
}

.hero-volume-up-solid:before {
  content: "\e9dc";
}

.hero-wifi-solid:before {
  content: "\e9dd";
}

.hero-x-circle-solid:before {
  content: "\e9de";
}

.hero-x-solid:before {
  content: "\e9df";
}

.hero-zoom-in-solid:before {
  content: "\e9e0";
}

.hero-zoom-out-solid:before {
  content: "\e9e1";
}

.hero-adjustments-outline:before {
  content: "\e9e2";
}

.hero-annotation-outline:before {
  content: "\e9e3";
}

.hero-archive-outline:before {
  content: "\e9e4";
}

.hero-arrow-circle-down-outline:before {
  content: "\e9e5";
}

.hero-arrow-circle-left-outline:before {
  content: "\e9e6";
}

.hero-arrow-circle-right-outline:before {
  content: "\e9e7";
}

.hero-arrow-circle-up-outline:before {
  content: "\e9e8";
}

.hero-arrow-down-outline:before {
  content: "\e9e9";
}

.hero-arrow-left-outline:before {
  content: "\e9ea";
}

.hero-arrow-narrow-down-outline:before {
  content: "\e9eb";
}

.hero-arrow-narrow-left-outline:before {
  content: "\e9ec";
}

.hero-arrow-narrow-right-outline:before {
  content: "\e9ed";
}

.hero-arrow-narrow-up-outline:before {
  content: "\e9ee";
}

.hero-arrow-right-outline:before {
  content: "\e9ef";
}

.hero-arrow-up-outline:before {
  content: "\e9f0";
}

.hero-arrows-expand-outline:before {
  content: "\e9f1";
}

.hero-at-symbol-outline:before {
  content: "\e9f2";
}

.hero-backspace-outline:before {
  content: "\e9f3";
}

.hero-badge-check-outline:before {
  content: "\e9f4";
}

.hero-ban-outline:before {
  content: "\e9f5";
}

.hero-beaker-outline:before {
  content: "\e9f6";
}

.hero-bell-outline:before {
  content: "\e9f7";
}

.hero-book-open-outline:before {
  content: "\e9f8";
}

.hero-bookmark-alt-outline:before {
  content: "\e9f9";
}

.hero-bookmark-outline:before {
  content: "\e9fa";
}

.hero-briefcase-outline:before {
  content: "\e9fb";
}

.hero-cake-outline:before {
  content: "\e9fc";
}

.hero-calculator-outline:before {
  content: "\e9fd";
}

.hero-calendar-outline:before {
  content: "\e9fe";
}

.hero-camera-outline:before {
  content: "\e9ff";
}

.hero-cash-outline:before {
  content: "\ea00";
}

.hero-chart-bar-outline:before {
  content: "\ea01";
}

.hero-chart-pie-outline:before {
  content: "\ea02";
}

.hero-chart-square-bar-outline:before {
  content: "\ea03";
}

.hero-chat-alt-2-outline:before {
  content: "\ea04";
}

.hero-chat-alt-outline:before {
  content: "\ea05";
}

.hero-chat-outline:before {
  content: "\ea06";
}

.hero-check-circle-outline:before {
  content: "\ea07";
}

.hero-check-outline:before {
  content: "\ea08";
}

.hero-cheveron-down-outline:before {
  content: "\ea09";
}

.hero-cheveron-left-outline:before {
  content: "\ea0a";
}

.hero-cheveron-right-outline:before {
  content: "\ea0b";
}

.hero-cheveron-up-outline:before {
  content: "\ea0c";
}

.hero-chevron-double-down-outline:before {
  content: "\ea0d";
}

.hero-chevron-double-left-outline:before {
  content: "\ea0e";
}

.hero-chevron-double-right-outline:before {
  content: "\ea0f";
}

.hero-chevron-double-up-outline:before {
  content: "\ea10";
}

.hero-chip-outline:before {
  content: "\ea11";
}

.hero-clipboard-check-outline:before {
  content: "\ea12";
}

.hero-clipboard-copy-outline:before {
  content: "\ea13";
}

.hero-clipboard-list-outline:before {
  content: "\ea14";
}

.hero-clipboard-outline:before {
  content: "\ea15";
}

.hero-clock-outline:before {
  content: "\ea16";
}

.hero-cloud-download-outline:before {
  content: "\ea17";
}

.hero-cloud-outline:before {
  content: "\ea18";
}

.hero-cloud-upload-outline:before {
  content: "\ea19";
}

.hero-code-outline:before {
  content: "\ea1a";
}

.hero-cog-outline:before {
  content: "\ea1b";
}

.hero-collection-outline:before {
  content: "\ea1c";
}

.hero-color-swatch-outline:before {
  content: "\ea1d";
}

.hero-credit-card-outline:before {
  content: "\ea1e";
}

.hero-cube-outline:before {
  content: "\ea1f";
}

.hero-cube-transparent-outline:before {
  content: "\ea20";
}

.hero-currency-bangladeshi-outline:before {
  content: "\ea21";
}

.hero-currency-dollar-outline:before {
  content: "\ea22";
}

.hero-currency-euro-outline:before {
  content: "\ea23";
}

.hero-currency-pound-outline:before {
  content: "\ea24";
}

.hero-currency-rupee-outline:before {
  content: "\ea25";
}

.hero-currency-yen-outline:before {
  content: "\ea26";
}

.hero-cursor-click-outline:before {
  content: "\ea27";
}

.hero-database-outline:before {
  content: "\ea28";
}

.hero-desktop-computer-outline:before {
  content: "\ea29";
}

.hero-device-mobile-outline:before {
  content: "\ea2a";
}

.hero-device-tablet-outline:before {
  content: "\ea2b";
}

.hero-document-add-outline:before {
  content: "\ea2c";
}

.hero-document-download-outline:before {
  content: "\ea2d";
}

.hero-document-duplicate-outline:before {
  content: "\ea2e";
}

.hero-document-outline:before {
  content: "\ea2f";
}

.hero-document-remove-outline:before {
  content: "\ea30";
}

.hero-document-report-outline:before {
  content: "\ea31";
}

.hero-document-search-outline:before {
  content: "\ea32";
}

.hero-document-text-outline:before {
  content: "\ea33";
}

.hero-dots-circle-horizontal-outline:before {
  content: "\ea34";
}

.hero-dots-horizontal-outline:before {
  content: "\ea35";
}

.hero-dots-vertical-outline:before {
  content: "\ea36";
}

.hero-download-outline:before {
  content: "\ea37";
}

.hero-duplicate-outline:before {
  content: "\ea38";
}

.hero-emoji-happy-outline:before {
  content: "\ea39";
}

.hero-emoji-sad-outline:before {
  content: "\ea3a";
}

.hero-exclamation-circle-outline:before {
  content: "\ea3b";
}

.hero-exclamation-outline:before {
  content: "\ea3c";
}

.hero-external-link-outline:before {
  content: "\ea3d";
}

.hero-eye-off-outline:before {
  content: "\ea3e";
}

.hero-eye-outline:before {
  content: "\ea3f";
}

.hero-fast-forward-outline:before {
  content: "\ea40";
}

.hero-film-outline:before {
  content: "\ea41";
}

.hero-filter-outline:before {
  content: "\ea42";
}

.hero-finger-print-outline:before {
  content: "\ea43";
}

.hero-fire-outline:before {
  content: "\ea44";
}

.hero-flag-outline:before {
  content: "\ea45";
}

.hero-folder-add-outline:before {
  content: "\ea46";
}

.hero-folder-download-outline:before {
  content: "\ea47";
}

.hero-folder-open-outline:before {
  content: "\ea48";
}

.hero-folder-outline:before {
  content: "\ea49";
}

.hero-folder-remove-outline:before {
  content: "\ea4a";
}

.hero-gift-outline:before {
  content: "\ea4b";
}

.hero-globe-alt-outline:before {
  content: "\ea4c";
}

.hero-globe-outline:before {
  content: "\ea4d";
}

.hero-hand-outline:before {
  content: "\ea4e";
}

.hero-hashtag-outline:before {
  content: "\ea4f";
}

.hero-heart-outline:before {
  content: "\ea50";
}

.hero-home-outline:before {
  content: "\ea51";
}

.hero-icon-user-remove-outline:before {
  content: "\ea52";
}

.hero-identification-outline:before {
  content: "\ea53";
}

.hero-inbox-in-outline:before {
  content: "\ea54";
}

.hero-inbox-outline:before {
  content: "\ea55";
}

.hero-information-circle-outline:before {
  content: "\ea56";
}

.hero-key-outline:before {
  content: "\ea57";
}

.hero-library-outline:before {
  content: "\ea58";
}

.hero-light-bulb-outline:before {
  content: "\ea59";
}

.hero-lightning-bolt-outline:before {
  content: "\ea5a";
}

.hero-link-outline:before {
  content: "\ea5b";
}

.hero-location-marker-outline:before {
  content: "\ea5c";
}

.hero-lock-closed-outline:before {
  content: "\ea5d";
}

.hero-lock-open-outline:before {
  content: "\ea5e";
}

.hero-logout-1-outline:before {
  content: "\ea5f";
}

.hero-logout-outline:before {
  content: "\ea60";
}

.hero-mail-open-outline:before {
  content: "\ea61";
}

.hero-mail-outline:before {
  content: "\ea62";
}

.hero-map-outline:before {
  content: "\ea63";
}

.hero-menu-alt-1-outline:before {
  content: "\ea64";
}

.hero-menu-alt-2-outline:before {
  content: "\ea65";
}

.hero-menu-alt-3-outline:before {
  content: "\ea66";
}

.hero-menu-alt-4-outline:before {
  content: "\ea67";
}

.hero-menu-outline:before {
  content: "\ea68";
}

.hero-microphone-outline:before {
  content: "\ea69";
}

.hero-minus-circle-outline:before {
  content: "\ea6a";
}

.hero-minus-1-outline:before {
  content: "\ea6b";
}

.hero-minus-outline:before {
  content: "\ea6c";
}

.hero-moon-outline:before {
  content: "\ea6d";
}

.hero-music-note-outline:before {
  content: "\ea6e";
}

.hero-newspaper-outline:before {
  content: "\ea6f";
}

.hero-office-building-outline:before {
  content: "\ea70";
}

.hero-paper-airplane-outline:before {
  content: "\ea71";
}

.hero-paper-clip-outline:before {
  content: "\ea72";
}

.hero-pause-outline:before {
  content: "\ea73";
}

.hero-pencil-alt-outline:before {
  content: "\ea74";
}

.hero-pencil-outline:before {
  content: "\ea75";
}

.hero-phone-incoming-outline:before {
  content: "\ea76";
}

.hero-phone-missed-call-outline:before {
  content: "\ea77";
}

.hero-phone-outgoing-outline:before {
  content: "\ea78";
}

.hero-phone-outline:before {
  content: "\ea79";
}

.hero-photograph-outline:before {
  content: "\ea7a";
}

.hero-play-outline:before {
  content: "\ea7b";
}

.hero-plus-circle-outline:before {
  content: "\ea7c";
}

.hero-plus-1-outline:before {
  content: "\ea7d";
}

.hero-plus-outline:before {
  content: "\ea7e";
}

.hero-presentation-chart-bar-outline:before {
  content: "\ea7f";
}

.hero-presentation-chart-line-outline:before {
  content: "\ea80";
}

.hero-printer-outline:before {
  content: "\ea81";
}

.hero-puzzle-outline:before {
  content: "\ea82";
}

.hero-qrcode-outline:before {
  content: "\ea83";
}

.hero-question-mark-circle-outline:before {
  content: "\ea84";
}

.hero-receipt-refund-outline:before {
  content: "\ea85";
}

.hero-receipt-tax-outline:before {
  content: "\ea86";
}

.hero-refresh-outline:before {
  content: "\ea87";
}

.hero-reply-outline:before {
  content: "\ea88";
}

.hero-rewind-outline:before {
  content: "\ea89";
}

.hero-rss-outline:before {
  content: "\ea8a";
}

.hero-save-as-outline:before {
  content: "\ea8b";
}

.hero-save-outline:before {
  content: "\ea8c";
}

.hero-scale-outline:before {
  content: "\ea8d";
}

.hero-scissors-outline:before {
  content: "\ea8e";
}

.hero-search-circle-outline:before {
  content: "\ea8f";
}

.hero-search-outline:before {
  content: "\ea90";
}

.hero-selector-outline:before {
  content: "\ea91";
}

.hero-server-outline:before {
  content: "\ea92";
}

.hero-share-outline:before {
  content: "\ea93";
}

.hero-shield-check-outline:before {
  content: "\ea94";
}

.hero-shield-exclamation-outline:before {
  content: "\ea95";
}

.hero-shopping-bag-outline:before {
  content: "\ea96";
}

.hero-shopping-cart-outline:before {
  content: "\ea97";
}

.hero-sm-view-grid-add-outline:before {
  content: "\ea98";
}

.hero-sort-ascending-outline:before {
  content: "\ea99";
}

.hero-sort-descending-outline:before {
  content: "\ea9a";
}

.hero-sparkles-outline:before {
  content: "\ea9b";
}

.hero-speakerphone-outline:before {
  content: "\ea9c";
}

.hero-star-outline:before {
  content: "\ea9d";
}

.hero-status-offline-outline:before {
  content: "\ea9e";
}

.hero-status-online-outline:before {
  content: "\ea9f";
}

.hero-stop-outline:before {
  content: "\eaa0";
}

.hero-sun-outline:before {
  content: "\eaa1";
}

.hero-support-outline:before {
  content: "\eaa2";
}

.hero-switch-horizontal-outline:before {
  content: "\eaa3";
}

.hero-switch-vertical-outline:before {
  content: "\eaa4";
}

.hero-table-outline:before {
  content: "\eaa5";
}

.hero-tag-outline:before {
  content: "\eaa6";
}

.hero-template-outline:before {
  content: "\eaa7";
}

.hero-terminal-outline:before {
  content: "\eaa8";
}

.hero-thumb-down-outline:before {
  content: "\eaa9";
}

.hero-thumb-up-outline:before {
  content: "\eaaa";
}

.hero-ticket-outline:before {
  content: "\eaab";
}

.hero-translate-outline:before {
  content: "\eaac";
}

.hero-trash-outline:before {
  content: "\eaad";
}

.hero-trending-down-outline:before {
  content: "\eaae";
}

.hero-trending-up-outline:before {
  content: "\eaaf";
}

.hero-truck-outline:before {
  content: "\eab0";
}

.hero-upload-outline:before {
  content: "\eab1";
}

.hero-user-add-outline:before {
  content: "\eab2";
}

.hero-user-circle-outline:before {
  content: "\eab3";
}

.hero-user-group-outline:before {
  content: "\eab4";
}

.hero-user-outline:before {
  content: "\eab5";
}

.hero-users-outline:before {
  content: "\eab6";
}

.hero-variable-outline:before {
  content: "\eab7";
}

.hero-video-camera-outline:before {
  content: "\eab8";
}

.hero-view-boards-outline:before {
  content: "\eab9";
}

.hero-view-grid-outline:before {
  content: "\eaba";
}

.hero-view-list-outline:before {
  content: "\eabb";
}

.hero-volume-off-outline:before {
  content: "\eabc";
}

.hero-volume-up-outline:before {
  content: "\eabd";
}

.hero-wifi-outline:before {
  content: "\eabe";
}

.hero-x-circle-outline:before {
  content: "\eabf";
}

.hero-x-outline:before {
  content: "\eac0";
}

.hero-zoom-in-outline:before {
  content: "\eac1";
}

.hero-zoom-out-outline:before {
  content: "\eac2";
}
