@import "~aftp-component-library/src/styles/theme";

::ng-deep .mat-tooltip {
  background: $gray--2;
  font: px2rem(12) / px2rem(20) $font-family--graphik-regular;
  letter-spacing: 0;
  border-radius: px2rem(6);
  // TODO: !important
  max-width: unset !important;
}

::ng-deep .icon-info {
  @include xs-screen {
    margin-top: 4px;
  }
}
::ng-deep {
  .mat-option {
    @media screen and (max-width: 767px) {
      touch-action: auto !important;
    }
  }
}
