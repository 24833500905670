@import "src/styles/themes";
@import "src/styles/theme";
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
//aicc related styles
//@import "../assets/partials/aicc/aicc-variables"; // To Do

html,
body {
  height: 100%;
  //added top:0 important as we are using google translate ,
  // Google creates iframe at the top of the page which is
  //causing the issues in the header
  top: 0 !important;
}
@media print {
  @page {
    margin-left: 0.5in;
    margin-right: 0.5in;
    margin-top: 0.5in;
    margin-bottom: 0.5in;
  }
}
//styling for Google translator
.goog-te-banner-frame.skiptranslate {
  display: none !important;
}

#goog-gt-tt {
  display: none !important;
  top: 0 !important;
}

.goog-tooltip .skiptranslate {
  display: none !important;
  top: 0 !important;
}

.transcript-class {
  .goog-te-gadget {
    text-align: center !important;
  }
  .goog-te-gadget .goog-te-combo {
    height: 47.75px !important;
    border: 1px solid $gray--6;
    border-radius: 6px;
    font-family: "Graphik Regular", sans-serif;
    font-size: 0.9rem;
    margin: 0;
  }
  h5 {
    font-family: "Graphik Medium", sans-serif !important;
  }

  .goog-te-gadget > div {
    padding-bottom: 5px;
  }
}

//end of styling for Google translator
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1900px,
);

/* Importing Bootstrap SCSS file. */
@import "~bootstrap/scss/bootstrap";

/* You can add global styles to this file, and also import other style files */
@import "../assets/partials/graphik";
// @import "../assets/partials/variables";
@import "../assets/partials/icomoonStyles";

@import "../styles/globals.scss";

.remote-launch {
  .error-wrapper {
    margin: 72px 14px 42px;
    @media screen and (min-width: 1281px) {
      margin: unset;
    }
    .error-flex {
      @media screen and (min-width: 1281px) {
        margin: unset;
        margin-top: 10%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .bigErrorLeft {
      width: 45%;
      float: left;
      @media screen and (min-width: 1281px) {
        float: unset;
      }
    }
    .bigErrorRight {
      width: 55%;
      float: right;
      @media screen and (min-width: 1281px) {
        float: unset;
        width: unset;
      }
    }
    .clearboth {
      clear: both;
    }
    .no-padding {
      padding: 0;
    }
    .errorTitle {
      font-size: 67px;
      font-family: "Graphik Regular", sans-serif;
      margin-bottom: 40px;
      margin-top: 72px;
      line-height: 1;
      color: #f52424;
    }
    .errorDescription {
      font-size: 18px;
      font-family: "Graphik Light", sans-serif;
      color: #333;
    }
  }

  .footerStyle {
    bottom: 10px;
    position: absolute;
    height: 18px;
    right: 10px;
    text-align: right;
  }

  /* --errorpage styles--:END */

  /* -loading image css:START- */

  .loadingOverlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1111111;
    .loader {
      position: absolute;
      left: 50%;
      top: 50%;
      margin-left: -50px;
      margin-top: -50px;
      img {
        width: 80px;
      }
    }
  }
  .banner {
    .goog-te-gadget-simple {
      margin: 14px -18px !important;
      padding: 2px !important;
      top: 147px !important;
      // width: 153px !important;
      height: 31px !important;
    }
    #google_translate_element {
      margin-left: 18px;
    }
  }

  /* --css related to GoogleTranslator--end */

  /* Loader related css start */

  .spinner {
    //margin: 100px auto;
    width: 50px;
    height: 40px;
    text-align: center;
    font-size: 10px;
  }

  .spinner > div {
    background-color: white;
    height: 90%;
    width: 6px;
    display: inline-block;
    margin-right: 3px;
    -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
    animation: sk-stretchdelay 1.2s infinite ease-in-out;
  }

  .spinner .rect2 {
    -webkit-animation-delay: -1s;
    animation-delay: -1s;
  }

  .spinner .rect3 {
    -webkit-animation-delay: -0.8s;
    animation-delay: -0.9s;
  }

  .spinner .rect4 {
    -webkit-animation-delay: -0.6s;
    animation-delay: -0.7s;
  }

  .spinner .rect5 {
    -webkit-animation-delay: -0.4s;
    animation-delay: -0.6s;
  }

  @-webkit-keyframes sk-stretchdelay {
    0%,
    40%,
    100% {
      -webkit-transform: scaleY(0.4);
    }
    20% {
      -webkit-transform: scaleY(1);
    }
  }

  @keyframes sk-stretchdelay {
    0%,
    40%,
    100% {
      transform: scaleY(0.4);
      -webkit-transform: scaleY(0.4);
    }
    20% {
      transform: scaleY(1);
      -webkit-transform: scaleY(1);
    }
  }

  /* Loader related css end */
}

a,
i,
li,
span,
div,
hero-icon,
aftp-avatar-profile,
svg-icon {
  &:focus {
    outline: $outline-3;
    outline-offset: 0;
  }
}

.cdk-global-scrollblock {
  position: initial;
  width: initial;
  overflow: hidden;
}

.pre-assessment-banner {
  .view-summary {
    color: $azure-radiance;
    text-decoration: none;
    background-color: transparent;
  }
  .view-summary:hover {
    color: $cobalt;
    text-decoration: none;
    cursor: pointer;
  }
  .anchor-pointer {
    color: $accent2 !important;
  }
}
